import { setPermissionsList, setPropertiesError, setStaffRolesList } from '../../slices/staffPropertiesSlice'
import APIService from '../../../api/api'

export function getAdminPermissionList(onSuccess: Function,onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getAdminPermissions()
            .then(response=> {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(setPermissionsList({
                        permissions: response.response,
                        error: null
                    }))
                    onSuccess()
                }else {
                    dispatch(setPropertiesError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            })
            .catch(error => {
                console.error(error)
                dispatch(setPropertiesError({
                    error: {
                        message: "Server Error",
                        status: "500"
                    }
                }))
            })
    }
}

export function getAdminRolesList(onSuccess: Function,onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getAdminRoles()
            .then(response=> {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(setStaffRolesList({
                        roles: response.response,
                        error: null
                    }))
                    onSuccess()
                }else {
                    dispatch(setPropertiesError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            })
            .catch(error => {
                console.error(error)
                dispatch(setPropertiesError({
                    error: {
                        message: "Server Error",
                        status: "500"
                    }
                }))
            })
    }
}