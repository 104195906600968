import React, { FC, useState } from 'react';
import styled from "styled-components";
import COLORS from '../../assets/Colors';
import Button from '../Form/Button';
import CheckableText from '../Form/CheckableText';
import DropdownField from '../Form/dropdownField';
import InputField from '../Form/InputField';

interface SearchFilterModalInterface {
    closeModal: Function;
    onMinValueChange: Function;
    onMaxValueChange: Function;
    onCategoryChange: Function;
    onFilterSubmit: Function;
    onStatusChange: Function;
    searchCategory: string;
    minPrice: number;
    maxPrice: number;
    status: string;
}

let ProductSearchFilterModal: FC<SearchFilterModalInterface> = (props) => {

    const [isUnder1000Selected, setIsUnder1000Selected] = useState(false)
    const [isBetween1000and5000Selected, setIsBetween1000and5000Selected] = useState(false)
    const [isBetween5000and15000Selected, setIsBetween5000and15000Selected] = useState(false)
    const [isAbove15000Selected, setIsAbove15000Selected] = useState(false)
    const [isMinMaxDisabled, setIsMinMaxDisabled] = useState(false)
    // const[minValue, setMinValue] = useState(0)
    // const[maxValue, setMaxValue] = useState(0)

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text
                    style={{ marginBottom: "20px", textAlign: "center", fontWeight: '500' }}
                >
                    Search Filter
                </Text>
                
                <div style={{marginBottom: 15}}>
                    <Text
                        style={{ fontSize: 16, marginBottom: "10px", fontWeight: '500' }}
                    >
                        Category
                    </Text>
                    {/*@Todo this drop down field should be values from the server, we could add it in an interface or something or pass them in as props*/}
                    <DropdownField
                        dropdownOptions={["", "Electronics", "Steering", "Headlight, Bulbs and Lights"]}
                        // containerStyling={{width: "100%", marginBottom: "10px"}}
                        // onSelect={(value)=> props.onCategoryChange(value)}
                        value={props.searchCategory}
                    />
                </div>

                <div>
                    <Text
                        style={{ fontSize: 16, fontWeight: '500', marginBottom: "10px", }}
                    >
                        Price
                    </Text>

                    <CheckableText
                        label='Under $1000'
                        value={isUnder1000Selected}
                        toggle={(e: boolean)=> {
                            setIsUnder1000Selected(e);
                            setIsBetween1000and5000Selected(false)
                            setIsBetween5000and15000Selected(false)
                            setIsAbove15000Selected(false)
                            props.onMinValueChange(1)
                            props.onMaxValueChange(1000)
                            // setMinValue(1)
                            // setMaxValue(1000)
                            setIsMinMaxDisabled(e)
                        }}
                    />
                    <CheckableText
                        label='$1000 to $5000'
                        value={isBetween1000and5000Selected}
                        toggle={(e: boolean)=> {
                            setIsUnder1000Selected(false);
                            setIsBetween1000and5000Selected(e)
                            setIsBetween5000and15000Selected(false)
                            setIsAbove15000Selected(false)
                            props.onMinValueChange(1000)
                            props.onMaxValueChange(5000)
                            // setMinValue(1000)
                            // setMaxValue(5000)
                            setIsMinMaxDisabled(e)
                        }}
                    />
                    <CheckableText
                        label='$5000 to $15,000'
                        value={isBetween5000and15000Selected}
                        toggle={(e: boolean)=> {
                            setIsUnder1000Selected(false);
                            setIsBetween1000and5000Selected(false)
                            setIsBetween5000and15000Selected(e)
                            setIsAbove15000Selected(false)
                            props.onMinValueChange(5000)
                            props.onMaxValueChange(15000)
                            // setMinValue(5000)
                            // setMaxValue(15000)
                            setIsMinMaxDisabled(e)
                        }}
                    />
                    <CheckableText
                        label='$15,000 & above'
                        value={isAbove15000Selected}
                        toggle={(e: boolean)=> {
                            setIsUnder1000Selected(false);
                            setIsBetween1000and5000Selected(false)
                            setIsBetween5000and15000Selected(false)
                            setIsAbove15000Selected(e)
                            props.onMinValueChange(15000)
                            props.onMaxValueChange(100000)
                            // setMinValue(15000)
                            // setMaxValue(100000)
                            setIsMinMaxDisabled(e)
                        }}
                    />

                    <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                        <InputField
                            placeholder="Min"
                            type="number"
                            value={props.minPrice}
                            min={0}
                            max={1000000}
                            containerStyles={{ width: '100px', marginRight: "20px" }}
                            onChange={(value: number)=> {
                                // setMinValue(value)
                                props.onMinValueChange(value);
                            }}
                            disabled={isMinMaxDisabled}
                        />
                        <InputField
                            placeholder="Max"
                            type="number"
                            value={props.maxPrice}
                            min={0}
                            max={1000000}
                            containerStyles={{ width: '100px' }}
                            onChange={(value: number)=> {
                                // setMaxValue(value)
                                props.onMaxValueChange(value)
                            }}
                            disabled={isMinMaxDisabled}
                        />
                    </div>
                </div>

                <div style={{marginBottom: 15}}>
                    <Text
                        style={{ fontSize: 16, fontWeight: '500', marginBottom: "10px", }}
                    >
                        Listing Status
                    </Text>

                    <DropdownField
                        dropdownOptions={["Available", "Unavailable"]}
                        // containerStyling={{width: "100%", marginBottom: "10px"}}
                        onChange={(value: string)=> props.onStatusChange(value)}
                        value={props.status}
                    />
                </div>

                <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr', gridColumnGap: '20px' }}>
                    <Button
                        label='Cancel'
                        containerStyles={{ width: '100%', backgroundColor: COLORS.red }}
                        // textStyling={{color: COLORS.green}}
                        onClick={() => props.closeModal()}
                    />
                    <Button
                        label='Confirm'
                        containerStyles={{ width: '100%' }}
                        onClick={props.onFilterSubmit}
                    />
                </div>

            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
`;

let ModalContainer = styled.div`
  min-width: 300px;
  max-width: 380px;
  /* height: 410px; */
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
`;

let Text = styled.p`
  font-weight: 400;
  font-size: 18px;
  padding: 0;
  margin: 0;
  color: ${COLORS.grey2};
`;

export default ProductSearchFilterModal;