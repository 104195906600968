import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiMenu, FiUser, FiShoppingBag, FiBox, FiMapPin, FiUsers, FiShield,FiDollarSign, FiBookmark } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import COLORS from '../../assets/Colors';

interface NavbarInterface {
    title: string;
}

let NavbarSmall: FC<NavbarInterface> = (props) => {
    let [activeTab, setActive] = useState("order")
    let [sideBarOpen, setSideBarOpen] = useState(false)
    let navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname.includes("product/list")) setActive("product")
        if (window.location.pathname.includes("order/list")) setActive("order")
        if(window.location.pathname.includes("pos/list")) setActive("pos")
        if (window.location.pathname.includes("customer/list")) setActive("customer")
        if (window.location.pathname.includes("staff/list")) setActive("staff")
        if(window.location.pathname.includes("campaign/list")) setActive("campaign")
    }, []);

    let navFn = (val:string) => {
        navigate(`/${val}/list`)
        setSideBarOpen(false)
    }

    return (
        <Container>
            <FiMenu size={30} color={'#fff'} onClick={() => setSideBarOpen(true)}/>
            <NavLabel>{props.title}</NavLabel>
            <div style={{
                background: '#fff',
                borderRadius: '100%',
                height: '30px',
                width: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <FiUser size={20} color={COLORS.black}/>
            </div>

            {sideBarOpen&&<ModalBackgroundContainer onClick={() => setSideBarOpen(false)}>
                <SlideInNavContainer>

                    <NavItemContainer onClick={() => navFn("order")} >
                        <FiShoppingBag size={25} style={{ marginBottom: '20px' }} color={activeTab === "order" ? COLORS.orange : "#fff"}/>
                        <Text>Order</Text>
                    </NavItemContainer>

                    <NavItemContainer onClick={() => navFn("pos")} >
                        <FiDollarSign size={25} style={{ marginBottom: '20px' }} color={activeTab === "pos" ? COLORS.orange : "#fff"}/>
                        <Text>P.O.S</Text>
                    </NavItemContainer>

                    <NavItemContainer onClick={() => navFn("product")} >
                        <FiBox size={25} style={{ marginBottom: '20px' }} color={activeTab === "product" ? COLORS.orange : "#fff"}/>
                        <Text>Product</Text>
                    </NavItemContainer>

                    <NavItemContainer onClick={() => navFn("campaign")}>
                        <FiBookmark size={25} style={{ marginBottom: '20px' }} color={activeTab === "campaign" ? COLORS.orange : "#fff"} />
                        <Text>Campaign</Text>
                    </NavItemContainer>

                    <NavItemContainer onClick={() => navFn("staff")}>
                        <FiShield size={25} style={{ marginBottom: '20px' }} color={activeTab === "staff" ? COLORS.orange : "#fff"}/>
                        <Text>Staff</Text>
                    </NavItemContainer>

                    <NavItemContainer onClick={() => navFn("customer")} >
                        <FiUsers size={25} style={{ marginBottom: '20px' }} color={activeTab === "customer" ? COLORS.orange : "#fff"}/>
                        <Text>Customer</Text>
                    </NavItemContainer>

                </SlideInNavContainer>
            </ModalBackgroundContainer>}

        </Container>
    )
}

let Container = styled.div`
    background-color: ${COLORS.black};
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 999;
`;

let NavLabel = styled.p`
    font-size: 18px;
    font-weight: 500;
    color: #fff;
`;

let Text = styled.p`
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-left: 15px;
`;


let ModalBackgroundContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    top: 0;
    left: 0;
`;

let SlideInNavContainer = styled.div`
    width: 50%;
    max-width: 200px;
    height: 100vh;
    background-color: ${COLORS.black};
    padding: 20px;
`;

let NavItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    &:hover {
        cursor: pointer;
    }
`;

export default NavbarSmall;