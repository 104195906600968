import store from '../redux/store'
import { ProductFilterRequest, ProductInterface, ProductRequestObjectInterface, ProductSearchRequestModel } from '../types/product/ProductInterface';
import { refreshAuthToken } from '../redux/thunks/auth/authThunks'
import { Order, OrderFilterRequest, orderSearchRequestModel, OrderUpdateRequest } from '../types/order/orderInterface';
import { StaffFilterRequest, StaffRequest, StaffSearchRequest } from '../types/staff/staffInterface';
import { CustomerFilterRequest, CustomerSearchRequest } from '../types/customer/customerInterface';
import { CreateSaleDetailsRequestModel, CreateSaleRequestModel, SaleDetail, UpdateSaleInformationRequestModel } from '../types/sale-pos/saleInterface';

interface ApiRequest {
    url: string,
    method: string,
    headers?: any,
    data?: any
}

class APIService {
    ADMIN_API_BASE_URL: any = process.env.REACT_APP_ADMIN_API_BASE_URL

    async apiRequest(requestConfig: ApiRequest) {
        const options = {
            method: requestConfig.method.toUpperCase(),
            body: JSON.stringify(requestConfig.data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + store.getState().auth.authToken,
                ...requestConfig.headers
            }
        };

        let response: Response;
        response = await fetch(`${this.ADMIN_API_BASE_URL}${requestConfig.url}`, options)
        /**
         * if token expired/no valid reauthenticate
         * then retry request
         */
        if (response.status === 403) {
            window.location.assign(`${process.env.REACT_APP_ADMIN_FRONTEND_BASE_URL}/`);
            store.dispatch({
                type: 'auth/reset'
            })
        }
        return response.json();
    }

    async apiRequestV2(requestConfig: ApiRequest) {
        const options = {
            method: requestConfig.method.toUpperCase(),
            body: requestConfig.data,
            headers: {
                'Authorization': "Bearer " + store.getState().auth.authToken,
                ...requestConfig.headers
            }
        };

        let response: Response;
        response = await fetch(`${this.ADMIN_API_BASE_URL}${requestConfig.url}`, options)
        console.log(response)
        /**
         * if token expired/no valid reauthenticate
         * then retry request
         */
        if (response.status === 403) {
            window.location.assign(`${process.env.REACT_APP_ADMIN_FRONTEND_BASE_URL}/`);
            store.dispatch({
                type: 'auth/reset'
            })
        }
        return response.json();
    }

    async apiRequestDownloadFile(requestConfig: ApiRequest) {
        const options = {
            method: requestConfig.method.toUpperCase(),
            body: requestConfig.data,
            responseType: 'blob',
            headers: {
                'Authorization': "Bearer " + store.getState().auth.authToken,
                ...requestConfig.headers
            }
        };

        let response: Response;
        response = await fetch(`${this.ADMIN_API_BASE_URL}${requestConfig.url}`, options)

        if (response.ok) {
            console.log(response)
            let filename = "";
            const header = response.headers.get('Content-Disposition');
            const parts = header ? header.split(';') : [];
            filename = parts.length>0 ? parts[1].split('=')[1].replaceAll("\"", "") : "";

            const href = URL.createObjectURL(await response.blob());

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }

        /**
         * if token expired/no valid reauthenticate
         * then retry request
         */
        if (response.status === 403) {
            window.location.assign(`${process.env.REACT_APP_ADMIN_FRONTEND_BASE_URL}/`);
            store.dispatch({
                type: 'auth/reset'
            })
        }
        return response;
    }


    // AUTH STUFF

    async loginRequest(email: string, password: string) {
        return await this.apiRequest({
            url: '/auth/admin/login',
            method: 'POST',
            data: { email, password }
        })
    }

    async refreshAuthToken() {
        return await this.apiRequest({
            url: '/auth/admin/refresh-token',
            method: 'GET'
        })
    }

    //============================================================================

    //PRODUCT STUFF
    async createNewProduct(data: ProductRequestObjectInterface) {
        return await this.apiRequest({
            url: '/admin/product',
            method: 'POST',
            data: data
        })
    }

    async getProductList(pageNo: number = 0, pageSize: number = 50) {
        return await this.apiRequest({
            url: `/admin/product?pageNo=${pageNo}&pageSize=${pageSize}`,
            method: 'GET'
        })
    }

    async updateProduct(data: ProductInterface) {
        return await this.apiRequest({
            url: '/admin/product/' + data.productId,
            method: 'PUT',
            data: data
        })
    }

    async getProductsByIDList(data: Array<number>) {
        return await this.apiRequest({
            url: '/admin/product/list',
            method: 'POST',
            data: data
        })
    }

    async productSearch(request: ProductSearchRequestModel) {
        return await this.apiRequest({
            url: '/admin/product/search',
            method: 'POST',
            data: request
        })
    }

    async fitlerProducts(request: ProductFilterRequest) {
        return await this.apiRequest({
            url: '/admin/product/filter',
            method: 'POST',
            data: request
        })
    }


    //==============================================================================

    async getCustomerList(pageNo: number = 0, pageSize: number = 50) {
        return await this.apiRequest({
            url: `/admin/customer/all?pageNo=${pageNo}&pageSize=${pageSize}`,
            method: 'GET'
        })
    }

    async getCustomerById(customerId: number) {
        return await this.apiRequest({
            url: `/admin/customer/${customerId}`,
            method: 'GET'
        })
    }

    async customerSearch(request: CustomerSearchRequest) {
        return await this.apiRequest({
            url: `/admin/customer/search`,
            method: 'Post',
            data: request
        })
    }

    async customerFilter(request: CustomerFilterRequest) {
        return await this.apiRequest({
            url: `/admin/customer/filter`,
            method: 'Post',
            data: request
        })
    }


    //===============================================================================

    async getOrderList(pageNo: number = 0, pageSize: number = 50) {
        return await this.apiRequest({
            url: `/admin/order/all?pageNo=${pageNo}&pageSize=${pageSize}&sortDir=desc&sortBy=id`,
            method: 'GET'
        })
    }

    async updateOrder(order: OrderUpdateRequest) {
        return await this.apiRequest({
            url: `/admin/order/${order.id}`,
            method: 'PUT',
            data: order
        })
    }

    async getOrderDetails(orderId: number) {
        return await this.apiRequest({
            url: `/admin/order-detail/order/${orderId}`,
            method: 'GET'
        })
    }

    async searchOrders(request: orderSearchRequestModel) {
        return await this.apiRequest({
            url: `/admin/order/search`,
            method: 'POST',
            data: request
        })
    }

    async filterOrders(request: OrderFilterRequest) {
        return await this.apiRequest({
            url: `/admin/order/filter?sortDir=desc&sortBy=id`,
            method: 'POST',
            data: request
        })
    }

    //================================================================================

    async getStaffList(pageNo: number = 0, pageSize: number = 50) {
        return await this.apiRequest({
            url: `/admin/all?pageNo=${pageNo}&pageSize=${pageSize}`,
            method: 'GET'
        })
    }

    async getAdminPermissions() {
        return await this.apiRequest({
            url: `/admin/permissions`,
            method: 'GET'
        })
    }

    async getAdminRoles() {
        return await this.apiRequest({
            url: `/admin/roles`,
            method: 'GET'
        })
    }

    async updateStaffInformation(request: StaffRequest) {
        return await this.apiRequest({
            url: `/admin/update`,
            method: `PUT`,
            data: request
        })
    }

    async createStaffInformation(request: StaffRequest) {
        return await this.apiRequest({
            url: `/admin/signup`,
            method: `POST`,
            data: request
        })
    }

    async searchStaff(request: StaffSearchRequest) {
        return await this.apiRequest({
            url: `/admin/search`,
            method: `POST`,
            data: request
        })
    }

    async filterStaff(request: StaffFilterRequest) {
        return await this.apiRequest({
            url: `/admin/filter`,
            method: `POST`,
            data: request
        })
    }

    //================================================================================

    async uploadProductImages(request: FormData) {
        return await this.apiRequestV2({
            url: `/files/product/image-upload`,
            method: `POST`,
            data: request
        })
    }

    async getProductImages(productId: number) {
        return await this.apiRequestV2({
            url: `/files/product/images?productId=${productId}`,
            method: `GET`
        })
    }

    //================================================================================

    async createPosSale(request: CreateSaleRequestModel) {
        return await this.apiRequest({
            url: `/admin/sale`,
            method: `POST`,
            data: request
        })
    }

    async getSalesByStatus(status: string, pageNo: number = 0, pageSize: number = 50) {
        return await this.apiRequest({
            url: `/admin/sale/status?status=${status}&page=${pageNo}&count=${pageSize}`,
            method: 'GET'
        })
    }

    async getSaleDetailsBySaleId(saleId: number) {
        return await this.apiRequest({
            url: `/admin/sale/${saleId}/details`,
            method: 'GET'
        })
    }

    async refundSale(saleId: number) {
        return await this.apiRequest({
            url: `/admin/sale/${saleId}/refund`,
            method: 'PATCH'
        })
    }

    async addSaleDetail(saleId: number, saleDetails: Array<CreateSaleDetailsRequestModel>) {
        return await this.apiRequest({
            url: `/admin/sale/${saleId}/add-products`,
            method: 'PATCH',
            data: {saleDetails: saleDetails}
        })
    }

    async updateSaleInformation(saleId: number, request: UpdateSaleInformationRequestModel) {
        return await this.apiRequest({
            url: `/admin/sale/${saleId}/update`,
            method: 'PATCH',
            data: request
        })
    }

    async deleteSaleDetail(saleDetailId: number) {
        return await this.apiRequest({
            url: `/admin/sale-detail/${saleDetailId}`,
            method: 'DELETE'
        })
    }

    async updateSaleDetailQuantity(request: { saleDetailId: number, quantity: number }) {
        return await this.apiRequest({
            url: `/admin/sale-detail/${request.saleDetailId}`,
            method: 'PATCH',
            data: { quantity: request.quantity }
        })
    }


    //========================================================================================

    async getOrdersReportWithinDateRange(request: { startState: string, endDate: string }) {
        return await this.apiRequestDownloadFile({
            headers: { 'Content-Type': 'text/csv' },
            url: `/admin/report/orders-date-range?start=${request.startState}&end=${request.endDate}`,
            method: `GET`
        })
    }

    async getSalesReportWithinDateRange(request: { startState: string, endDate: string }) {
        return await this.apiRequestDownloadFile({
            headers: { 'Content-Type': 'text/csv' },
            url: `/admin/report/sales-date-range?start=${request.startState}&end=${request.endDate}`,
            method: `GET`
        })
    }


    //=========================================================================================
    async createCampaign(request: FormData) {
        return await this.apiRequestV2({
            url: `/admin/campaign`,
            method: `POST`,
            data: request
        })
    }

    async updateCampaign(request: FormData) {
        return await this.apiRequestV2({
            url: `/admin/campaign`,
            method: `PATCH`,
            data: request
        })
    }

    async getCampaignList(pageNo: number = 0, pageSize: number = 100) {
        return await this.apiRequest({
            url: `/admin/campaign?page=${pageNo}&count=${pageSize}`,
            method: 'GET'
        })
    }


}
export default APIService;