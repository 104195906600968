import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiSearch, FiFilter, FiBarChart } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageContainer from '../components/universal/PageContainer'
import TabBar from '../components/universal/TabBar'
import InputField from '../components/Form/InputField';
import COLORS from '../assets/Colors';
import useWindowDimensions from '../utils/useWindowDimensions';
import Button from '../components/Form/Button';
import StaffCard from '../components/cards/StaffCard'
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import { filterStaffRequest, getStaffList, searchStaffRequest } from '../redux/thunks/staff/staffThunk'
import { getAdminPermissionList, getAdminRolesList } from '../redux/thunks/staffProperties/staffPropertiesThunk'
import { Staff, StaffFilterRequest, StaffSearchRequest } from '../types/staff/staffInterface';
import DropdownField from '../components/Form/dropdownField';
import PageStepper from '../components/universal/PageSteper';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

let StaffList: FC<any> = (props) => {

    let { width, height } = useWindowDimensions()
    let authCheck = useAuthenticationCheck()
    let navigate = useNavigate();

    let [searchString, setSearchString] = useState("");

    let [pageNumber, setPageNumber] = useState(0);
    let [totalElements, setTotalElements] = useState(0);
    let [totalPages, setTotalPages] = useState(1);
    let [lastPage, setIsLastPage] = useState(true);
    let [pageSize, setPageSize] = useState(50);

    let updatePageNumber = (updatedPageNumber: number) => {
        setPageNumber(updatedPageNumber);
        handleGetStaffList(pageNumber, pageSize);
    }

    useEffect(() => {
        authCheck()
        if (props.staffProperties.permissionList.length === 0) props.getPermissionList(() => { }, () => { })
        if (props.staffProperties.rolesList.length === 0) props.getRolesList(() => { }, () => { })
        if (props.staff.staffList === undefined || props.staff.staffList.length == 0) {
            handleGetStaffList()
        }
    }, [])

    let handleGetStaffList = (pageNo: number = 0, pageSize: number = 50) => {
        props.getStaffList(
            { pageNo, pageSize },
            () => { },
            () => {
                //TODO: show modal
            },
        )
    }

    let handleAddStaff = () => {
        navigate("/staff/info?create_new_staff=true")
    }

    let handleClickOrder = (orderId: string | number) => {
        navigate(`/staff/info?staffId=${orderId}`)
    }

    let submitSearch = () => {
        let request: StaffSearchRequest = {
            searchString: searchString,
            pageNumber: 0,
            itemCount: 50
        }
        props.getSearchResult(request, () => { }, () => { })
    }

    let filter = (active: boolean) => {
        let request: StaffFilterRequest = {
            pageNumber: 0,
            itemCount: 50,
            searchFlag: active,
            checkActive: true
        }
        props.getFilterResult(request, () => { }, () => { })
    }

    const columns: GridColDef[] = [
        { field: 'staffId', headerName: 'Staff Id', width: 130 },
        {
            field: 'firstName',
            headerName: 'Staff Name',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.firstName + " " + params.row.lastName}`,
        },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phoneNumber', headerName: 'Phone Number', width: 180 },
        {
            field: 'staffRolle', headerName: 'Role', width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.staffRole ? params.row.staffRole.roleName : "N/A"}`
        },

    ];

    return (
        <PageContainer
            navBarTitle={"Staff"}
        >
            <PageContentContainer>
                {width && width >= 1024 && <Text>Staff</Text>}
                <SearchContainer>
                    <InputField
                        icon={<FiSearch size={30} color={COLORS.orange} />}
                        placeholder="Search Staff, e.g sta-1 or email@domain.com"
                        onChange={(value: string) => setSearchString(value)}
                        submit={() => submitSearch()}
                        containerStyles={{ marginBottom: width && width < 600 ? '20px' : 0, width: '100%', maxWidth: '350px', marginRight: width && width < 600 ? 0 : 20 }}
                    />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        maxWidth: 450
                    }}>
                        <Button
                            // icon={<FiBarChart size={25} color={COLORS.border} style={{ marginRight: 10 }} />}
                            label="Add Staff"
                            containerStyles={{
                                width: 160,
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: COLORS.blue,
                                color: "#fff"
                            }}
                            onClick={handleAddStaff}
                        />
                    </div>
                </SearchContainer>

                <div
                    style={{
                        marginTop: 10,
                        borderBottom: `2px solid ${COLORS.border}`,
                        paddingBottom: '20px'
                    }}
                >
                    <TabBar
                        tabs={["All", "Active", "InActive"]}
                        onClick={(status: string) => {
                            if (status === 'Active') {
                                filter(true)
                            } else if (status === "InActive") {
                                filter(false)
                            } else {
                                handleGetStaffList()
                            }

                        }}
                    />
                </div>

                <div style={{
                    height: '100%',
                    width: '100%'
                }}>
                    <DataGrid
                        rows={props.staff.staffList}
                        columns={columns}
                        getRowId={(row) => row.staffId}
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids)
                            const selectedRowData: [Staff] = props.staff.staffList.filter((staff: Staff) =>
                                selectedIds.has(staff.staffId)
                            );
                            handleClickOrder(selectedRowData[0].staffId)
                        }}
                        pagination
                    />

                </div>

            </PageContentContainer>
        </PageContainer>
    )
};

let PageContentContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

let SearchContainer = styled.div`
    border-bottom: 2px solid ${COLORS.border};
    padding-bottom: 20px;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

let StaffListContainer = styled.div`
    margin-top: 20px;
    overflow-y: scroll;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        /* justify-content: center; */
        grid-template-columns: repeat(auto-fit, 350px);
    }
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        grid-template-columns: repeat(auto-fit, 380px);
    }
`;

let Text = styled.p`
    font-size: 32px;
    color: ${COLORS.black};
    font-weight: 500;
    margin-bottom: 15px;
`;

let StepperContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;


function mapStateToProps(state: any) {
    return {
        staff: state.staff,
        staffProperties: state.staffProperties
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        getStaffList: (data: { pageNo: number, pageSize: number }, onSuccess: Function, onFailure: Function) => {
            dispatch(getStaffList(data, onSuccess, onFailure));
        },
        getPermissionList: (onSuccess: Function, onFailure: Function) => {
            dispatch(getAdminPermissionList(onSuccess, onFailure))
        },
        getRolesList: (onSuccess: Function, onFailure: Function) => {
            dispatch(getAdminRolesList(onSuccess, onFailure))
        },
        getSearchResult: (request: StaffSearchRequest, onSuccess: Function, onFailure: Function) => {
            dispatch(searchStaffRequest(request, onSuccess, onFailure))
        },
        getFilterResult: (request: StaffFilterRequest, onSuccess: Function, onFailure: Function) => {
            dispatch(filterStaffRequest(request, onSuccess, onFailure))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffList);