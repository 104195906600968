import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import COLORS from '../assets/Colors';
import useWindowDimensions from '../utils/useWindowDimensions';
import PageContainer from '../components/universal/PageContainer'
import Button from '../components/Form/Button';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import { connect } from 'react-redux';
import TabBar from '../components/universal/TabBar';
import InputField from '../components/Form/InputField';
import { FiSearch, FiTrash } from 'react-icons/fi';
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import Divider from '@mui/material/Divider';
import { getProductList, getProductListByArray, getProductSearchResults } from '../redux/thunks/product/productThunk';
import { ProductInterface, ProductSearchRequestModel } from '../types/product/ProductInterface';
import { CreateSaleDetailsRequestModel, CreateSaleRequestModel, SaleDetail, SaleModel, SaleProductInterface, UpdateSaleInformationRequestModel } from '../types/sale-pos/saleInterface';
import { addSaleItemDetails, createNewSale, deleteSaleDetailItem, getSaleDetails, saleRefund, updateSaleItemQuantity, updateSaleInformation } from '../redux/thunks/sale/saleThunk';
import ModalContainer from '../components/modal/ModalContainer';
import ConfirmationModal from '../components/modal/ConfirmationModal';
import LoadingModal from '../components/modal/LoadingModal';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';
import Chip from '@mui/material/Chip/Chip';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import DropdownField from '../components/Form/dropdownField';


let PosInfo: FC<any> = (props) => {
    let { width } = useWindowDimensions()
    let navigate = useNavigate();

    let [newSale, setNewSale] = useState(true);
    let authCheck = useAuthenticationCheck()
    const [searchParams, setSearchParams] = useSearchParams();
    let [tabSelection, setTabSelection] = useState<string>("Order");
    let [showRefundConfirmationModal, setShowRefundConfirmationModal] = useState(false)
    let [showLoadingModal, setShowLoadingModal] = useState(false);
    let [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
    let [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
    let [successSnackBarMessage, setSuccessSnackBarMessage] = useState("");
    let [errorSnackBarMessage, setErrorSnackBarMessage] = useState("");
    let [disableSale, setDisableSale] = useState(false);

    let [pageNumber, setPageNumber] = useState(0);
    let [totalElements, setTotalElements] = useState(0);
    let [totalPages, setTotalPages] = useState(1);
    let [lastPage, setIsLastPage] = useState(true);
    let [pageSize, setPageSize] = useState(50);

    let [searchString, setSearchString] = useState("");
    let [searchObject, setSearchObject] = useState<{ category: string, maxPrice: number, minPrice: number, listingStatus: string }>({
        category: '', maxPrice: 0, minPrice: 0, listingStatus: ''
    });

    let [saleItems, setSaleItems] = useState<Array<SaleProductInterface>>([])
    let [totalOrderPrice, setTotalOrderPrice] = useState(0);
    let [custName, setCustName] = useState<string | null>(null)
    let [custPhoneNumber, setCustPhoneNumnber] = useState<string | null>(null)
    let [custEmail, setCustEmail] = useState<string | null>(null)
    let [discountReason, setDiscountReason] = useState<string>("N/A")
    let [custDiscount, setCustDiscount] = useState<number>(0)

    let [saleRecord, setSaleRecord] = useState<SaleModel>({} as SaleModel)

    useEffect(() => {
        authCheck()
    }, [])

    useEffect(() => {
        if (searchParams.get("saleId") !== null) {
            let querySaleId = searchParams.get("saleId") || ""
            if (querySaleId !== "") {
                setSaleRecord(props.sales.salesList.find((item: SaleModel) => item.saleId === Number.parseInt(querySaleId)))
                retrieveSalesDetails((Number.parseInt(querySaleId)))
            }
            setNewSale(false)
        }
    }, [searchParams])

    useEffect(() => {
        if (saleRecord.status === "REFUNDED") {
            setDisableSale(true);
        } else {
            setDisableSale(false);
        }
    }, [saleRecord])

    useEffect(() => {
        if (searchParams.get("create_new_sale") !== null && searchParams.get("create_new_sale") === "true") {
            setNewSale(true)
        }
    }, [searchParams])

    useEffect(() => {
        if (tabSelection === 'Search') {
            handleGetProductList(pageNumber, pageSize)
        }
    }, [tabSelection])

    useEffect(() => {
        let finalPrice = 0;
        saleItems.forEach(item => {
            finalPrice += item.productPrice * item.selectedQuantity
        })
        setTotalOrderPrice(finalPrice)
    }, [saleItems])

    useEffect(() => {
        setCustDiscount(saleRecord.custDiscount * 100)
        setCustName(saleRecord.custName)
        setCustEmail(saleRecord.custEmail)
        setCustPhoneNumnber(saleRecord.custPhoneNumber)
        setDiscountReason(saleRecord.discountReason)
    }, [saleRecord])

    let handleGetProductList = (pageNo: number = 0, pageSize: number = 50) => {
        props.getProductList(
            { pageNo, pageSize },
            (response: any) => {
                setPageSize(response.pageSize)
                setTotalElements(response.totalElements)
                setTotalPages(response.totalPages)
                setIsLastPage(response.last)
            }
        )
    }

    // let isCorrectStateSaleDetails = (saleId: number): boolean => {
    //     if (props.sales.saleDetailList.length === 0) return false;
    //     return props.sales.saleDetailList.every((item: SaleDetail) => {
    //         return item.saleId === saleId
    //     })
    // }

    let submitSearch = () => {
        if (searchString !== "") {
            let request: ProductSearchRequestModel = {
                searchString: searchString,
                pageNumber: 0,
                itemCount: 50,
                searchFilter: searchObject
            }
            props.productSearch(request, () => { }, () => { })
        } else {
            handleGetProductList(pageNumber, pageSize)
        }
    }

    let retrieveSalesDetails = (saleId: number) => {
        props.getSalesDetails(
            saleId,
            (saleDetails: SaleDetail[]) => {
                let productIds = saleDetails.map(item => item.productId);
                retrieveSaleProducts(productIds, saleDetails)
            },
            () => { }
        )
    }

    let retrieveSaleProducts = (ids: number[], saleDetails: SaleDetail[]) => {
        props.getProductsByIdList(
            ids,
            (products: ProductInterface[]) => {
                let tempSalesItems: any = []
                products.forEach(item => {
                    let saleDetail = saleDetails.find(saleItem => saleItem.productId === item.productId);
                    if (saleDetail) tempSalesItems.push({ ...item, selectedQuantity: saleDetail?.quantity })
                })
                setSaleItems(tempSalesItems)
            },
            () => { }
        )
    }

    let updateSaleItemQuantity = (itemIndex: number, quantity: number) => {
        let temp = [...saleItems];
        temp[itemIndex].selectedQuantity = quantity;

        if (!newSale) {
            let saleDetail: SaleDetail = props.sales.saleDetailList.find((item: SaleDetail) => item.productId === temp[itemIndex].productId)
            props.updateSaleDetailQuantity(
                { quantity: quantity, saleDetailId: saleDetail.saleDetailId },
                () => {
                    setSaleItems(temp)
                    setSuccessSnackBarMessage("Quantity Updated")
                    setSuccessSnackBarOpen(true)
                },
                () => {
                    setErrorSnackBarMessage("Quantity Update Error")
                    setErrorSnackBarOpen(true)
                }
            )
        } else {
            setSaleItems(temp)
        }
    }

    let deleteSaleItem = (itemIndex: number) => {
        let temp = [...saleItems];
        let saleItem = temp[itemIndex];
        let item: SaleDetail = props.sales.saleDetailList.find((val: SaleDetail) => val.productId === saleItem.productId);

        if (item && !newSale) {
            props.deleteSaleDetail(
                item.saleDetailId,
                () => {
                    temp.splice(itemIndex, 1)
                    setSaleItems(temp)
                    setSuccessSnackBarMessage("Item Deleted")
                    setSuccessSnackBarOpen(true)
                },
                () => {
                    setErrorSnackBarMessage("Item Delete Error")
                    setErrorSnackBarOpen(true)
                }
            )
        } else {
            temp.splice(itemIndex, 1)
            setSaleItems(temp)
        }
    }

    let deleteSaleItemByProductId = (productId: number) => {
        let temp = [...saleItems];
        let itemIndex = temp.findIndex(item => item.productId === productId)
        let item: SaleDetail = props.sales.saleDetailList.find((val: SaleDetail) => val.productId === productId);

        if (item && !newSale) {
            props.deleteSaleDetail(
                item.saleDetailId,
                () => {
                    temp.splice(itemIndex, 1)
                    setSaleItems(temp)
                    setSuccessSnackBarMessage("Item Deleted")
                    setSuccessSnackBarOpen(true)
                },
                () => {
                    setErrorSnackBarMessage("Item Delete Error")
                    setErrorSnackBarOpen(true)
                }
            )
        } else {
            temp.splice(itemIndex, 1)
            setSaleItems(temp)
        }
    }

    let handleCancel = () => {
        navigate("/pos/list")
    }

    let handleCreateNewSale = () => {
        if (newSale !== true) return; // show a modal with a message?

        let items: Array<CreateSaleDetailsRequestModel> = [];

        saleItems.forEach(item => {
            items.push({
                productId: item.productId,
                quantity: item.selectedQuantity,
                details: item.details
            })
        })

        // let requestObject: CreateSaleRequestModel = ;
        // console.log(requestObject)
        props.createSale(
            {
                custEmail: null,
                custName: null,
                custPhoneNumber: null,
                custDiscount: 0,
                discountReason:  "N/A",
                saleDetails: items
            },
            (sale: SaleModel, saleDetails: Array<SaleDetail>) => {
                setSearchParams({ "create_new_sale": "false" })
                setSearchParams({ "saleId": sale.saleId.toString() })
                setSaleRecord(sale)
                setSuccessSnackBarMessage("Sale Created Successfully")
                setSuccessSnackBarOpen(true)
                //show modal message
            },
            () => {
                setErrorSnackBarMessage("Sale Creation Error")
                setErrorSnackBarOpen(true)
                //show modal message
            }
        )
    }

    let handleRefund = () => {
        setShowRefundConfirmationModal(false);
        setShowLoadingModal(true)
        props.refundSale(
            saleRecord,
            (sale: SaleModel) => {
                setSaleRecord(sale);
                setShowLoadingModal(false)
                setSuccessSnackBarMessage("Refund Successful")
                setSuccessSnackBarOpen(true)
                //show message modal
            },
            () => {
                setShowLoadingModal(false)
                setErrorSnackBarMessage("Refund Error")
                setErrorSnackBarOpen(true)
            }
        )
    }

    let updateSaleInformation = () => {
        let request: UpdateSaleInformationRequestModel = {
            custEmail: custEmail !== saleRecord.custEmail ? custEmail : saleRecord.custEmail,
            custName: custName !== saleRecord.custName ? custName : saleRecord.custName,
            custPhoneNumber: custPhoneNumber !== saleRecord.custPhoneNumber ? custPhoneNumber : saleRecord.custPhoneNumber,
            custDiscount: custDiscount !== saleRecord.custDiscount ? custDiscount : saleRecord.custDiscount,
            discountReason: discountReason !== saleRecord.discountReason ? discountReason : saleRecord.discountReason
        }

        props.updateSaleInformation(
            { saleId: saleRecord.saleId, data: request },
            (response: any) => {
                setSuccessSnackBarMessage("Information Updated Successfully")
                setSuccessSnackBarOpen(true)
                setSaleRecord(response)
            },
            () => {
                setErrorSnackBarMessage("Information Update Error")
                setErrorSnackBarOpen(true)
            }
        )

    }

    let addSaleItem = (item: SaleProductInterface) => {
        if (item.productId === undefined) return;
        if (!newSale) {
            props.addSaleDetails(
                {
                    saleId: saleRecord.saleId,
                    saleDetails: [{ productId: item.productId, quantity: item.selectedQuantity, details: item.details }]
                },
                () => {
                    setSuccessSnackBarMessage("Item Added Successfully")
                    setSuccessSnackBarOpen(true)
                    let temp = [...saleItems];
                    temp.push(item)
                    setSaleItems(temp)
                },
                () => {
                    setErrorSnackBarMessage("Item Add Error")
                    setErrorSnackBarOpen(true)
                }
            )
        } else {
            let temp = [...saleItems];
            temp.push(item)
            setSaleItems(temp)
            setSuccessSnackBarMessage("Item Added Successfully")
            setSuccessSnackBarOpen(true)
        }
    }

    const selectedProductsColumns: GridColDef[] = [
        { field: 'productId', headerName: 'Product ID', width: 130 },
        { field: 'productName', headerName: 'Product Name', width: 200 },
        { field: 'category', headerName: 'Category', width: 150 },
        {
            field: 'selectedQuantity',
            headerName: 'Order Quantity',
            width: 150,
            renderCell: (params: GridRenderCellParams<number>) => (
                <strong>
                    <DropdownField
                        // label="Discount % (Optional)"
                        dropdownOptions={Array.from({ length: params.row.quantity }, (_, i) => i + 1)}
                        // disabled={!creatingNewProduct}
                        value={params.value?.toString()}
                        onChange={(val: string) => {
                            let index = saleItems.findIndex(item => item.productId === params.row.productId)
                            updateSaleItemQuantity(index, Number.parseInt(val))
                        }}
                        containerStyles={{ width: '100%' }}
                    />
                </strong>
            ),
        },
        {
            field: 'productPrice',
            headerName: 'Unit Price',
            valueGetter: (params: GridValueGetterParams) =>
                `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.row.productPrice)}`,
        },
        {
            field: '',
            headerName: 'Actions',
            renderCell: (params: GridRenderCellParams<number>) => (
                <strong>
                    <FiTrash size={24} color={COLORS.red} onClick={() => {
                        let index = saleItems.findIndex(item => item.productId === params.row.productId)
                        deleteSaleItem(index)
                    }} />
                </strong>
            ),
        }


    ];

    const productsColumns: GridColDef[] = [
        { field: 'productId', headerName: 'Product ID', width: 130 },
        { field: 'productName', headerName: 'Product Name', width: 130 },
        { field: 'category', headerName: 'Category', width: 130 },
        {
            field: 'quantity',
            headerName: 'Available Quantity',
            width: 100,
        },
        {
            field: 'productPrice',
            headerName: 'Unit Price',
            valueGetter: (params: GridValueGetterParams) =>
                `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.row.productPrice)}`,
        },
    ];

    return (
        <PageContainer
            navBarTitle={"Sale"}
        >

            {width && width <= 500 && <MobileView
                tabSelection={tabSelection}
                setTabSelection={setTabSelection}
                productInfo={props.productInfo}
                searchString={searchString}
                setSearchString={setSearchString}
                submitSearch={submitSearch}
                saleItems={saleItems}
                totalOrderPrice={totalOrderPrice}
                selectProduct={(item: SaleProductInterface) => addSaleItem(item)}
                updateSaleQuantity={updateSaleItemQuantity}
                deleteSalesQuantity={deleteSaleItem}
                deleteSaleItemByProductId={deleteSaleItemByProductId}
                handleCancel={handleCancel}
                handleCreateNewSale={handleCreateNewSale}
                newSale={newSale}
                sale={saleRecord}
                disableSale={disableSale}
                showRefundModal={() => setShowRefundConfirmationModal(true)}
                selectionProductsColumns={selectedProductsColumns}
                productsColumns={productsColumns}
                setCustName={setCustName}
                setCustEmail={setCustEmail}
                setCustPhoneNumber={setCustPhoneNumnber}
                setCustDiscount={setCustDiscount}
                setDiscountReason={setDiscountReason}
                custEmail={custEmail}
                custName={custName}
                custPhoneNumber={custPhoneNumber}
                custDiscount={custDiscount}
                discountReason={discountReason}
                updateSaleInformation={updateSaleInformation}
            />}

            {width && width > 500 && <TabletDesktopView
                tabSelection={tabSelection}
                setTabSelection={setTabSelection}
                productInfo={props.productInfo}
                searchString={searchString}
                setSearchString={setSearchString}
                submitSearch={submitSearch}
                saleItems={saleItems}
                totalOrderPrice={totalOrderPrice}
                selectProduct={(item: SaleProductInterface) => addSaleItem(item)}
                updateSaleQuantity={updateSaleItemQuantity}
                deleteSalesQuantity={deleteSaleItem}
                deleteSaleItemByProductId={deleteSaleItemByProductId}
                handleCancel={handleCancel}
                handleCreateNewSale={handleCreateNewSale}
                newSale={newSale}
                sale={saleRecord}
                disableSale={disableSale}
                showRefundModal={() => setShowRefundConfirmationModal(true)}
                selectionProductsColumns={selectedProductsColumns}
                productsColumns={productsColumns}
                setCustName={setCustName}
                setCustEmail={setCustEmail}
                setCustPhoneNumber={setCustPhoneNumnber}
                setCustDiscount={setCustDiscount}
                setDiscountReason={setDiscountReason}
                custEmail={custEmail}
                custName={custName}
                custPhoneNumber={custPhoneNumber}
                custDiscount={custDiscount}
                discountReason={discountReason}
                updateSaleInformation={updateSaleInformation}
            />}

            <Snackbar open={successSnackBarOpen} autoHideDuration={6000} onClose={() => setSuccessSnackBarOpen(false)}>
                <Alert onClose={() => setSuccessSnackBarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {successSnackBarMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={errorSnackBarOpen} autoHideDuration={6000} onClose={() => setErrorSnackBarOpen(false)}>
                <Alert onClose={() => setErrorSnackBarOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {errorSnackBarMessage}
                </Alert>
            </Snackbar>

            <ModalContainer visible={showRefundConfirmationModal} >
                <ConfirmationModal
                    message={"Are you sure you want to refund this sale?"}
                    closeModal={() => setShowRefundConfirmationModal(false)}
                    onClickConfirm={() => handleRefund()}
                />
            </ModalContainer>

            <ModalContainer visible={showLoadingModal} >
                <LoadingModal />
            </ModalContainer>
        </PageContainer>
    )
}

interface ViewInterface {
    newSale: boolean
    tabSelection: string;
    productInfo: any;
    setTabSelection: Function;
    searchString: string;
    setSearchString: Function;
    submitSearch: Function;
    selectProduct: Function;
    saleItems: Array<SaleProductInterface>;
    totalOrderPrice: number;
    updateSaleQuantity: Function;
    deleteSalesQuantity: Function;
    deleteSaleItemByProductId: Function;
    handleCancel: Function;
    handleCreateNewSale: Function
    showRefundModal: Function;
    sale: SaleModel
    disableSale: boolean;
    selectionProductsColumns: GridColDef[]
    productsColumns: GridColDef[]
    setCustName: Function
    setCustEmail: Function
    setCustPhoneNumber: Function
    setCustDiscount: Function
    setDiscountReason: Function
    custEmail: string | null
    custName: string | null
    custPhoneNumber: string | null
    custDiscount: number
    discountReason: string | null
    updateSaleInformation: Function
}

let TabletDesktopView: FC<ViewInterface> = (props) => {

    let [filteredSearchProduct, setFilteredSearchProducts] = useState<Array<ProductInterface>>([])

    useEffect(() => {
        setFilteredSearchProducts(props.productInfo.products.filter((item: ProductInterface) => props.saleItems.find(saleItem => saleItem.productId === item.productId) === undefined)
            .filter((product: ProductInterface) => product.quantity > 0))
    }, [props.saleItems])

    return (
        <Section>
            <TabletDesktopSectionSeperator>
                <TabletDesktopSection
                    style={{
                        maxWidth: 750,
                        justifySelf: 'end'
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: 'center',
                                paddingBottom: '15px',
                                borderBottom: `2px solid ${COLORS.border}`,
                                marginBottom: '15px',
                                width: '100%'
                            }}
                        >
                            <Text style={{ fontWeight: 500, fontSize: 24 }}>Sale Info{props.sale.saleId ? <span style={{ fontWeight: 400 }}>: Sal-{props.sale.saleId}</span> : null}</Text>
                        </div>
                        <TabBar
                            tabs={["Order", "Search"]}
                            onClick={(status: string) => {
                                props.setTabSelection(status)
                            }}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        {props.tabSelection === "Search" && <InputField
                            icon={<FiSearch size={30} color={COLORS.orange} />}
                            placeholder="Search by Product parameters"
                            onChange={(value: string) => props.setSearchString(value)}
                            submit={() => props.submitSearch()}
                            containerStyles={{ marginBottom: 15, width: '100%', maxWidth: 390, minWidth: 335 }}
                        />}
                    </div>

                    {props.tabSelection === "Order" &&
                        <div style={{
                            height: '100%',
                            width: '100%'
                        }}>
                            <DataGrid
                                rows={props.saleItems}
                                columns={props.selectionProductsColumns}
                                getRowId={(row) => row.productId}
                                onSelectionModelChange={(ids) => {
                                    const selectedIds = new Set(ids)
                                    // const selectedRowData: [SaleProductInterface] = props.saleItems.filter((sale: SaleModel) =>
                                    //     selectedIds.has(sale.prod)
                                    // );
                                    // handleClickSale(selectedRowData[0].saleId)
                                }}
                                pagination
                            />

                        </div>}

                    {props.tabSelection === "Search" &&
                        <DataGrid
                            rows={filteredSearchProduct}
                            columns={props.productsColumns}
                            getRowId={(row) => row.productId}
                            onSelectionModelChange={(ids) => {
                                const selectedIds = new Set(ids)
                                const selectedRowData: Array<ProductInterface> = filteredSearchProduct.filter((sale: ProductInterface) =>
                                    selectedIds.has(sale.productId)
                                );
                                props.selectProduct({ ...selectedRowData[0], selectedQuantity: 1 })
                            }}
                            pagination
                            checkboxSelection
                        />
                    }
                </TabletDesktopSection>

                <TabletDesktopSection
                    style={{
                        maxWidth: 390,
                        justifySelf: 'start'
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <InputField
                            label="Customer Name (Optional)"
                            // disabled={!editProductInfo}
                            value={props.custName}
                            onChange={(val: string) => props.setCustName(val)}
                            textFieldStyles={{ color: COLORS.green }}
                            containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                        />
                        <InputField
                            label="Phone Number (Optional)"
                            // disabled={!editProductInfo}
                            value={props.custPhoneNumber}
                            onChange={(val: string) => props.setCustPhoneNumber(val)}
                            // inputStyles={{ width: 90 }}
                            containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                        />

                        <InputField
                            label="Email Address (Optional)"
                            // disabled={!editProductInfo}
                            value={props.custEmail}
                            onChange={(val: string) => props.setCustEmail(val)}
                            // inputStyles={{ width: 90 }}
                            containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                        />

                        <Divider style={{ width: '100%', marginBottom: 15 }} />

                        <DropdownField
                            label="Discount % (Optional)"
                            dropdownOptions={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50]}
                            // disabled={!creatingNewProduct}
                            value={(props.custDiscount).toString()}
                            onChange={(val: string) => props.setCustDiscount(Number.parseInt(val))}
                            containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                        />

                        <InputField
                            label="Discount Reason (Required for discount)"
                            // disabled={!editProductInfo}
                            value={props.discountReason}
                            onChange={(val: string) => props.setDiscountReason(val)}
                            // inputStyles={{ width: 90 }}
                            containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                        />

                        <Button
                            label={"Update"}
                            containerStyles={{ width: '100%', marginBottom: 15, maxWidth: 390, minWidth: 335 }}
                            disabled={props.saleItems.length === 0}
                            onClick={() => {
                                props.updateSaleInformation()
                            }}
                        />
                    </div>

                    <ButtonContainer>
                        <Divider style={{ width: '100%', marginBottom: 15 }} />
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: 15
                            }}
                        >
                            <Text>Status</Text>
                            <Chip label={props.sale.status ? props.sale.status : 'N/A'} color={props.sale.status && props.sale.status === "FULFILLED" ? "success" : props.sale.status && props.sale.status === "REFUNDED" ? "info" : "default"} />
                        </div>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: 15
                            }}
                        >
                            <Text>Discounted Price</Text>
                            <Text
                                style={{ fontWeight: 500 }}
                            >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.sale.discountPrice ? props.sale.discountPrice : 0)}</Text>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: 15
                            }}
                        >
                            <Text>Total (Original)</Text>
                            <Text
                                style={{ fontWeight: 500 }}
                            >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.totalOrderPrice)}</Text>
                        </div>
                        {props.newSale && <Button
                            label={"Submit Sale"}
                            containerStyles={{ width: '100%', marginBottom: 15, maxWidth: 390, minWidth: 335, backgroundColor: COLORS.green }}
                            disabled={props.saleItems.length === 0}
                            onClick={() => {
                                props.handleCreateNewSale()
                            }}
                        />}
                        {props.newSale && <Text style={{ color: COLORS.red, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => props.handleCancel()}>Cancel</Text>}
                        {!props.newSale && props.sale.status !== "REFUNDED" && <Text style={{ color: COLORS.red, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => props.showRefundModal()}>Refund</Text>}
                    </ButtonContainer>
                </TabletDesktopSection>
            </TabletDesktopSectionSeperator>
        </Section>
    )
}

let MobileView: FC<ViewInterface> = (props) => {
    let { width } = useWindowDimensions()
    let [filteredSearchProduct, setFilteredSearchProducts] = useState<Array<ProductInterface>>([])

    useEffect(() => {
        setFilteredSearchProducts(props.productInfo.products.filter((item: ProductInterface) => props.saleItems.find(saleItem => saleItem.productId === item.productId) === undefined))
    }, [props.saleItems])
    return (
        <MobileSection >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: 'center',
                        paddingBottom: '15px',
                        borderBottom: `2px solid ${COLORS.border}`,
                        marginBottom: '15px',
                        width: '100%'
                    }}
                >
                    <Text style={{ fontWeight: 500, fontSize: 24 }}>Sale Info{props.sale.saleId ? <span style={{ fontWeight: 400 }}>: Sal-{props.sale.saleId}</span> : null}</Text>
                </div>
                <TabBar
                    tabs={["Order", "Search", "Order Info"]}
                    onClick={(status: string) => {
                        props.setTabSelection(status)
                    }}
                    containerStyles={{ marginBottom: 15 }}
                />
                {props.tabSelection === "Search" && <InputField
                    icon={<FiSearch size={30} color={COLORS.orange} />}
                    placeholder="Search by Product parameters"
                    onChange={(value: string) => props.setSearchString(value)}
                    submit={() => props.submitSearch()}
                    containerStyles={{ width: '100%', maxWidth: 390, minWidth: 335 }}
                />}
            </div>

            <CardListContainer>
                {props.tabSelection === "Order" &&
                    <div style={{
                        height: '100%',
                        width: '100%'
                    }}>
                        <DataGrid
                            rows={props.saleItems}
                            columns={props.selectionProductsColumns}
                            getRowId={(row) => row.productId}
                            onSelectionModelChange={(ids) => {
                                const selectedIds = new Set(ids)
                                // const selectedRowData: [SaleProductInterface] = props.saleItems.filter((sale: SaleModel) =>
                                //     selectedIds.has(sale.prod)
                                // );
                                // handleClickSale(selectedRowData[0].saleId)
                            }}
                            pagination
                        />

                    </div>}

                {props.tabSelection === "Search" &&
                    <DataGrid
                        rows={filteredSearchProduct}
                        columns={props.productsColumns}
                        getRowId={(row) => row.productId}
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids)
                            const selectedRowData: Array<ProductInterface> = filteredSearchProduct.filter((sale: ProductInterface) =>
                                selectedIds.has(sale.productId)
                            );
                            props.selectProduct({ ...selectedRowData[0], selectedQuantity: 1 })
                        }}
                        pagination
                        checkboxSelection
                    />
                }

                {props.tabSelection === "Order Info" && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                    <InputField
                        label="Customer Name (Optional)"
                        // disabled={!editProductInfo}
                        value={props.custName}
                        onChange={(val: string) => props.setCustName(val)}
                        textFieldStyles={{ color: COLORS.green }}
                        containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                    />
                    <InputField
                        label="Phone Number (Optional)"
                        // disabled={!editProductInfo}
                        value={props.custPhoneNumber}
                        onChange={(val: string) => props.setCustPhoneNumber(val)}
                        // inputStyles={{ width: 90 }}
                        containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                    />

                    <InputField
                        label="Email Address (Optional)"
                        // disabled={!editProductInfo}
                        value={props.custEmail}
                        onChange={(val: string) => props.setCustEmail(val)}
                        // inputStyles={{ width: 90 }}
                        containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                    />

                    <Divider style={{ width: '100%', marginBottom: 15 }} />

                    <DropdownField
                        label="Discount % (Optional)"
                        dropdownOptions={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50]}
                        // disabled={!creatingNewProduct}
                        value={(props.custDiscount).toString()}
                        onChange={(val: string) => props.setCustDiscount(Number.parseInt(val))}
                        containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                    />

                    <InputField
                        label="Discount Reason (Required for discount)"
                        // disabled={!editProductInfo}
                        value={props.discountReason}
                        onChange={(val: string) => props.setDiscountReason(val)}
                        // inputStyles={{ width: 90 }}
                        containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                    />

                    <Button
                        label={"Update"}
                        containerStyles={{ width: '100%', marginBottom: 15, maxWidth: 390, minWidth: 335 }}
                        disabled={props.saleItems.length === 0}
                        onClick={() => {
                            props.updateSaleInformation()
                        }}
                    />
                </div>}
            </CardListContainer>

            {props.tabSelection === "Order Info" && <ButtonContainer>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 15
                    }}
                >
                    <Text>Status</Text>
                    <Chip label={props.sale.status ? props.sale.status : 'N/A'} color={props.sale.status && props.sale.status === "FULFILLED" ? "success" : props.sale.status && props.sale.status === "REFUNDED" ? "info" : "default"} />
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 15
                    }}
                >
                    <Text>Discounted Price</Text>
                    <Text
                        style={{ fontWeight: 500 }}
                    >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.sale.discountPrice ? props.sale.discountPrice : 0)}</Text>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 15
                    }}
                >
                    <Text>Total (Original)</Text>
                    <Text
                        style={{ fontWeight: 500 }}
                    >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.totalOrderPrice)}</Text>
                </div>
                {props.newSale && <Button
                    label={"Submit Sale"}
                    containerStyles={{ width: '100%', marginBottom: 15, maxWidth: 390, minWidth: 335, backgroundColor: COLORS.green }}
                    disabled={props.saleItems.length === 0}
                    onClick={() => {
                        props.handleCreateNewSale()
                    }}
                />}
                {props.newSale && <Text style={{ color: COLORS.red, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => props.handleCancel()}>Cancel</Text>}
                {!props.newSale && props.sale.status !== "REFUNDED" && <Text style={{ color: COLORS.red, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => props.showRefundModal()}>Refund</Text>}
            </ButtonContainer>}
        </MobileSection>
    )
}

let Section = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
`;

let TabletDesktopSection = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 6fr auto;
    width: 100%;
    max-height: 100vh;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
`;

let TabletDesktopSectionSeperator = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    width: 100%;
`;

let ProductListContainer = styled.div`
    width: 100%;
    overflow-y: scroll;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        justify-content: center;
        grid-gap: 20px;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        /* justify-content: center; */
        grid-template-columns: repeat(auto-fit, 335px);
    }
    /* @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        grid-template-columns: repeat(auto-fit, 380px);
    } */
`;

let MobileSection = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 6fr auto;
    width: 100%;
    max-height: 100vh;
    padding: 20px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
`;


let CardListContainer = styled.div`
    /* height: 100%; */
    overflow-y: scroll;
`;

let ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let Text = styled.p`
    font-size: 16px;
    color: ${COLORS.black};
    font-weight: 400;
`;


function mapStateToProps(state: any) {
    return {
        productInfo: state.products,
        sales: state.sales
    }
}

function mapDispatchToProps(dispatch: Function) {
    return {
        getProductList: (data: { pageNo: number, pageSize: number }, onSuccess: Function, onFailure: Function) => {
            dispatch(getProductList(data, onSuccess, onFailure));
        },
        productSearch: (request: ProductSearchRequestModel, onSuccess?: Function, onFailure?: Function) => {
            dispatch(getProductSearchResults(request, onSuccess, onFailure))
        },
        createSale: (data: CreateSaleRequestModel, onSuccess: Function, onError: Function) => {
            dispatch(createNewSale(data, onSuccess, onError))
        },
        getSalesDetails: (saleId: number, onSuccess: Function, onError: Function) => {
            dispatch(getSaleDetails(saleId, onSuccess, onError))
        },
        getProductsByIdList: (ids: number[], onSuccess: Function, onError: Function) => {
            dispatch(getProductListByArray(ids, onSuccess, onError))
        },
        deleteSaleDetail: (saleDetailId: number, onSuccess: Function, onError: Function) => {
            dispatch(deleteSaleDetailItem(saleDetailId, onSuccess, onError))
        },
        refundSale: (sale: SaleModel, onSuccess: Function, onError: Function) => {
            dispatch(saleRefund(sale, onSuccess, onError))
        },
        updateSaleDetailQuantity: (request: {
            quantity: number;
            saleDetailId: number;
        }, onSuccess: Function, onError: Function) => {
            dispatch(updateSaleItemQuantity(request, onSuccess, onError))
        },
        addSaleDetails: (data: {
            saleId: number;
            saleDetails: [SaleDetail];
        }, onSuccess: Function, onError: Function) => {
            dispatch(addSaleItemDetails(data, onSuccess, onError))
        },
        updateSaleInformation: (data: { saleId: number, data: UpdateSaleInformationRequestModel }, onSuccess: Function, onError: Function) => {
            dispatch(updateSaleInformation(data, onSuccess, onError))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosInfo);