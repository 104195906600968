import APIService from '../../../api/api';
import { CreateSaleDetailsRequestModel, CreateSaleRequestModel, SaleDetail, SaleModel, UpdateSaleInformationRequestModel } from '../../../types/sale-pos/saleInterface';
import { addSale, addSaleDetails, addSaleError, addSalesList, deleteSaleDetail, updateSale, updateSaleDetailQuantity } from '../../slices/saleSlices';


export function createNewSale(data: CreateSaleRequestModel, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.createPosSale(data)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("201")){
                    dispatch(addSale({sale: response.response.sale, saleDetails: response.response.saleDetail}))
                    onSuccess(response.response.sale, response.response.saleDetail)
                }else{
                    dispatch(addSaleError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error=> {
            console.error(error)
            dispatch(addSaleError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
            onError()
        })
    }
}

export function addSaleItemDetails(data: {saleId: number, saleDetails: Array<CreateSaleDetailsRequestModel>}, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.addSaleDetail(data.saleId, data.saleDetails)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("201")){
                    dispatch(addSaleDetails(response.response.saleDetail))
                    onSuccess(response.response.sale, response.response.saleDetail)
                }else{
                    dispatch(addSaleError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error=> {
            console.error(error)
            dispatch(addSaleError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
            onError()
        })
    }
}

export function saleRefund (sale: SaleModel, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.refundSale(sale.saleId)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("OK")){
                    dispatch(updateSale(response.response))
                    onSuccess(response.response)
                }else{
                    dispatch(addSaleError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error=> {
            console.error(error)
            dispatch(addSaleError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
            onError()
        })
    }
}

export function getSalesByStatus(queryParam: {status: string, pageNo: number, pageSize: number}, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getSalesByStatus(queryParam.status,queryParam.pageNo, queryParam.pageSize)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("OK")){
                    dispatch(addSalesList({sales: response.response.content ? response.response.content : [], pageNo: response.response.pageNo}))
                    onSuccess(response.response)
                }else{
                    dispatch(addSaleError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error=> {
            console.error(error)
            dispatch(addSaleError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
            onError()
        })
    }
}

export function getSaleDetails(saleId: number, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getSaleDetailsBySaleId(saleId)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("OK")){
                    dispatch(addSaleDetails(response.response))
                    onSuccess(response.response)
                }else{
                    dispatch(addSaleError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error=> {
            console.error(error)
            dispatch(addSaleError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
            onError()
        })
    }
}

export function deleteSaleDetailItem(saleDetailId: number, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.deleteSaleDetail(saleDetailId)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("OK")){
                    dispatch(deleteSaleDetail({saleDetailId}))
                    onSuccess(response.response)
                }else{
                    dispatch(addSaleError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error=> {
            console.error(error)
            dispatch(addSaleError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
            onError()
        })
    }
}

export function updateSaleItemQuantity(request: {quantity: number, saleDetailId: number}, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.updateSaleDetailQuantity(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("OK")){
                    dispatch(updateSaleDetailQuantity(request))
                    onSuccess(response.response)
                }else{
                    dispatch(addSaleError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error=> {
            console.error(error)
            dispatch(addSaleError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
            onError()
        })
    }
}

export function updateSaleInformation(request: {saleId: number, data: UpdateSaleInformationRequestModel}, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.updateSaleInformation(request.saleId, request.data)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("OK")){
                    dispatch(updateSale(request))
                    onSuccess(response.response)
                }else{
                    dispatch(addSaleError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error=> {
            console.error(error)
            dispatch(addSaleError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
            onError()
        })
    }
}