import { combineReducers, applyMiddleware, createStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import authSlice from './slices/authSlices';
import productSlice from './slices/productSlice'
import customerSlice from './slices/customerSlices'
import orderSlice from './slices/orderSlices'
import staffSlice from './slices/staffSlices'
import staffPropertiesSlice from "./slices/staffPropertiesSlice";
import saleSlices from "./slices/saleSlices";
import campaignSlices from "./slices/campaignSlices";

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["products","customers", "orders","staff","staffProperties", "sales", "campaign"]
}

const reducer = combineReducers({
  auth: authSlice,
  products: productSlice,
  customers: customerSlice,
  orders: orderSlice,
  staff: staffSlice,
  sales: saleSlices,
  staffProperties: staffPropertiesSlice,
  campaign: campaignSlices
})

//TODO: remove in production?
const composedEnhancer = composeWithDevTools(
  applyMiddleware(thunk)
)

const persistedReducer = persistReducer(persistConfig, reducer)
let store = createStore(persistedReducer, composedEnhancer)
export let persistor = persistStore(store)
export default store