import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiSearch, FiFilter, FiBarChart } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageContainer from '../components/universal/PageContainer'
import TabBar from '../components/universal/TabBar'
import InputField from '../components/Form/InputField';
import COLORS from '../assets/Colors';
import useWindowDimensions from '../utils/useWindowDimensions';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import Button from '../components/Form/Button';
import { getSalesByStatus } from '../redux/thunks/sale/saleThunk';
import { SaleModel } from '../types/sale-pos/saleInterface';
import APIService from '../api/api';
import { format } from 'date-fns';
import { Snackbar, Alert } from '@mui/material';
import DateRangeReportModal from '../components/modal/DateRangeReportModal';
import LoadingModal from '../components/modal/LoadingModal';
import ModalContainer from '../components/modal/ModalContainer';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

let PostList: FC<any> = (props) => {

    let { width } = useWindowDimensions()
    let authCheck = useAuthenticationCheck()
    let navigate = useNavigate();

    let [searchString, setSearchString] = useState("");

    let [tabSelection, setTabSelection] = useState<string>("FulFilled")
    let [pageNumber, setPageNumber] = useState(0);
    let [totalElements, setTotalElements] = useState(0);
    let [totalPages, setTotalPages] = useState(1);
    let [lastPage, setIsLastPage] = useState(true);
    let [pageSize, setPageSize] = useState(50);

    let [showReportDateSelectionModal, setShowReportDateSelectionModal] = useState(false);
    let [showLoadingModal, setShowLoadingModal] = useState(false);
    let [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
    let [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
    let [successSnackBarMessage, setSuccessSnackBarMessage] = useState("");
    let [errorSnackBarMessage, setErrorSnackBarMessage] = useState("");

    let updatePageNumber = (updatedPageNumber: number) => {
        setPageNumber(updatedPageNumber);
        handleGetSalesList(updatedPageNumber, pageSize);
    }


    useEffect(() => {
        authCheck()
        if (props.sales.salesList === undefined || props.sales.salesList.length == 0) {
            handleGetSalesList()
        }
    }, [])

    let handleGetSalesList = (pageNo: number = 0, pageSize: number = 100, status?: string) => {
        props.getSales(
            { status: status ? status.toUpperCase() : tabSelection.toUpperCase(), pageNo, pageSize },
            (response: any) => {
                setPageSize(response.pageSize)
                setTotalElements(response.totalElements)
                setTotalPages(response.totalPages)
                setIsLastPage(response.last)
            },
            () => {
                //TODO: show modal
            },
        )
    }

    let handleGetSaleReportWithinDateRange = (startDate: Date, endDate: Date) => {
        let apiService = new APIService();
        // debugger
        let start = format(startDate, 'yyyy-MM-dd')
        let end = format(endDate, 'yyyy-MM-dd')

        setShowLoadingModal(true)
        setShowReportDateSelectionModal(false)
        apiService.getSalesReportWithinDateRange({ startState: start, endDate: end })
            .then(res => {
                setSuccessSnackBarMessage("Report Generated")
                setSuccessSnackBarOpen(true)
                setShowLoadingModal(false)
            })
            .catch(() => {
                setErrorSnackBarMessage("Error Generating Report")
                setErrorSnackBarOpen(true)
                setShowLoadingModal(false)
            })
    }

    let handleClickSale = (saleId: string | number) => {
        navigate(`/pos/info?saleId=${saleId}`)
    }

    let handleAddSale = () => {
        navigate("/pos/info?create_new_sale=true")
    }

    const columns: GridColDef[] = [
        { field: 'saleId', headerName: 'Sale ID', width: 130 },
        { field: 'status', headerName: 'Status', width: 130 },
        { field: 'actionedBy', headerName: 'Actioned By', width: 130 },
        { field: 'custName', headerName: 'Customer Name', width: 130 },
        { field: 'custEmail', headerName: 'Customer Email', width: 130 },
        { field: 'custPhoneNumber', headerName: 'Customer Phone Number', width: 130 },
        {
            field: 'createdAt',
            headerName: 'Order Date',
            type: 'date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
                `${format(new Date(params.row.createdAt), 'EEE PP')}`,
        },
        {
            field: 'custDiscount', headerName: 'Customer Discount Rate', width: 150,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.custDiscount*100}%`
        },
        { field: 'discountReason', headerName: 'Discount Reason', width: 200 },
        {
            field: 'discountPrice', headerName: 'Discount Sale Price', width: 130,
            valueGetter: (params: GridValueGetterParams) =>
                `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.row.discountPrice)}`
        },
        {
            field: 'orderTotal',
            headerName: 'Total',
            valueGetter: (params: GridValueGetterParams) =>
                `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.row.orderTotal)}`,
        },

    ];

    return (
        <PageContainer
            navBarTitle={"Sales"}
        >
            <PageContentContainer>
                {width && width > 1024 && <Text>Sales</Text>}
                <SearchContainer>
                    <InputField
                        icon={<FiSearch size={30} color={COLORS.orange} />}
                        placeholder="Search  Sales, e.g sal-2 or sal-4"
                        onChange={(value: string) => setSearchString(value)}
                        submit={() => { }}
                        containerStyles={{ marginBottom: width && width < 600 ? '20px' : 0, width: '100%', maxWidth: '350px', marginRight: width && width < 600 ? 0 : 20 }}
                    />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        maxWidth: 450
                    }}>
                        <Button
                            // icon={<FiFilter size={25} color={COLORS.border} style={{ marginRight: 10 }} />}
                            label="Generate Report"
                            onClick={() => setShowReportDateSelectionModal(true)}
                            containerStyles={{
                                width: 160,
                                display: 'flex',
                                justifyContent: 'center',
                                border: `2px solid ${COLORS.border}`,
                                backgroundColor: "#fff",
                                color: COLORS.grey1,
                                marginRight: width && width < 600 ? 0 : 20
                            }}
                        />

                        <Button
                            // icon={<FiBarChart size={25} color={COLORS.border} style={{ marginRight: 10 }} />}
                            label="Create Sale"
                            containerStyles={{
                                width: 160,
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: COLORS.blue,
                                color: "#fff"
                            }}
                            onClick={handleAddSale}
                        />
                    </div>
                </SearchContainer>

                <div
                    style={{
                        marginTop: 10,
                        borderBottom: `2px solid ${COLORS.border}`,
                        paddingBottom: '20px'
                    }}
                >
                    <TabBar
                        tabs={["FulFilled", "Refunded"]}
                        onClick={(status: string) => {
                            setTabSelection(status)
                            handleGetSalesList(pageNumber, pageSize, status);
                        }}
                    />
                </div>

                <div style={{
                    height: '100%',
                    width: '100%'
                }}>
                    <DataGrid
                        rows={props.sales.salesList}
                        columns={columns}
                        getRowId={(row) => row.saleId}
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids)
                            const selectedRowData: [SaleModel] = props.sales.salesList.filter((sale: SaleModel) =>
                                selectedIds.has(sale.saleId)
                            );
                            handleClickSale(selectedRowData[0].saleId)
                        }}
                        pagination
                    />

                </div>

            </PageContentContainer>

            <ModalContainer visible={showReportDateSelectionModal}>
                <DateRangeReportModal
                    message='Select the Start and End date range below'
                    closeModal={() => setShowReportDateSelectionModal(false)}
                    onClickConfirm={(data: { startDate: Date, endDate: Date }) => handleGetSaleReportWithinDateRange(data.startDate, data.endDate)}
                />
            </ModalContainer>
            <ModalContainer visible={showLoadingModal} >
                <LoadingModal />
            </ModalContainer>

            <Snackbar open={successSnackBarOpen} autoHideDuration={6000} onClose={() => setSuccessSnackBarOpen(false)}>
                <Alert onClose={() => setSuccessSnackBarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {successSnackBarMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={errorSnackBarOpen} autoHideDuration={6000} onClose={() => setErrorSnackBarOpen(false)}>
                <Alert onClose={() => setErrorSnackBarOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {errorSnackBarMessage}
                </Alert>
            </Snackbar>
        </PageContainer>
    )
};

let PageContentContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

let SearchContainer = styled.div`
    border-bottom: 2px solid ${COLORS.border};
    padding-bottom: 20px;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

let OrderListContainer = styled.div`
    margin-top: 20px;
    overflow-y: scroll;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        /* justify-content: center; */
        grid-template-columns: repeat(auto-fit, 350px);
    }
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        grid-template-columns: repeat(auto-fit, 380px);
    }
`;

let Text = styled.p`
    font-size: 32px;
    color: ${COLORS.black};
    font-weight: 500;
    margin-bottom: 15px;
`;

let StepperContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;


function mapStateToProps(state: any) {
    return {
        sales: state.sales
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        getSales: (queryParam: { status: string, pageNo: number, pageSize: number }, onSuccess: Function, onFailure: Function) => {
            dispatch(getSalesByStatus(queryParam, onSuccess, onFailure));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostList);