import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    authenticated: false,
    authToken: null,
    error:  null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAuth(state, action) {
            return {...state, authenticated: action.payload.authenticated, authToken: action.payload.authToken, error: null}
        },
        updateAuthError(state,action){
            return{...state, error: action.payload.error, authenticated: false}
        },
        reset: (state, action) => {
            return initialState;
        }
    }
})

export const {updateAuth, updateAuthError} = authSlice.actions;

export default authSlice.reducer;