import { createSlice } from '@reduxjs/toolkit'
import { Staff } from '../../types/staff/staffInterface'

const initialState = {
    staffList: new Array<Staff>(),
    error: null,
    pageNo: 0
}

const staffSlice = createSlice({
    name: 'staffs',
    initialState,
    reducers: {
        addStaffList(state, action) {
            // if (state.staffList === undefined)
                return { ...state, staffList: [...action.payload.staffs], pageNo: action.payload.pageNo, error: null }
            // return { ...state, staffList: [...state.staffList, ...action.payload.staffs], pageNo: action.payload.pageNo, error: null }
        },
        addStaffSearchList(state, action) {
            return { ...state, staffList: [...action.payload.staffs], pageNo: action.payload.pageNo, error: null }
        },
        addStaffError(state,action){
            return {...state, error: action.payload.error}
        },
        updateStaffInformation(state, action){
            let staffList = state.staffList.filter(staff=>staff.staffId!==action.payload.staffId)
            staffList.push(action.payload)
            return {...state, staffList: [...staffList]}
        },
        addToStaffList(state, action) {
            if (state.staffList === undefined)
                return { ...state, staffList: [action.payload], error: null }
            return { ...state, staffList: [...state.staffList, action.payload], error: null }
        },
        
    }
})

export const {addStaffList, addStaffError,updateStaffInformation,addToStaffList,addStaffSearchList} = staffSlice.actions;
export default staffSlice.reducer;