import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiSearch, FiFilter, FiBarChart } from 'react-icons/fi';
import { connect } from 'react-redux';

import PageContainer from '../components/universal/PageContainer'
import TabBar from '../components/universal/TabBar'
import InputField from '../components/Form/InputField';
import COLORS from '../assets/Colors';
import useWindowDimensions from '../utils/useWindowDimensions';
import Button from '../components/Form/Button';
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import {getCustomerFilterList, getCustomerList, getCustomerSearchList} from '../redux/thunks/customer/customerThunk'
import {Customer, CustomerFilterRequest, CustomerSearchRequest} from '../types/customer/customerInterface'
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

let CustomerList: FC<any> = (props) => {

    let { width, height } = useWindowDimensions()
    let [searchString, setSearchString] = useState("");

    let authCheck = useAuthenticationCheck()

    let navigate = useNavigate();

    let [pageNumber, setPageNumber] = useState(0);
    let [totalElements, setTotalElements] = useState(0);
    let [totalPages, setTotalPages] = useState(1);
    let [lastPage, setIsLastPage] = useState(true);
    let [pageSize, setPageSize] = useState(50);

    let updatePageNumber = (updatedPageNumber: number) => {
        setPageNumber(updatedPageNumber);
        handleGetCustomerList(pageNumber, pageSize);
    }


    useEffect(()=>{
        authCheck()
        if(props.customers.customerList===undefined || props.customers.customerList.length==0 ) {
            handleGetCustomerList()
        }
    },[])

    let handleGetCustomerList = (pageNo: number = 0, pageSize: number = 100) => {
        props.getCustomerList(
            {pageNo, pageSize},
            (response: any) => {
                setPageSize(response.pageSize)
                setTotalElements(response.totalElements)
                setTotalPages(response.totalPages)
                setIsLastPage(response.last)
            },
            ()=>{
                //TODO: show modal
            },
        )
    }

    let handleClickCustomer = (customerId: string|number) => {
        navigate(`/customer/info?customerId=${customerId}`)
    }

    let submitSearch = () => {
        let request: CustomerSearchRequest = {
            searchString: searchString,
            pageNumber: 0,
            itemCount: 50
        }
        props.searchCustomer(request, ()=>{},()=>{})
    }

    let filter = (searchValue: boolean, checkActive: boolean, checkVerified: boolean, checkSocialAuthed: boolean) => {
        let request: CustomerFilterRequest = {
            pageNumber: 0,
            itemCount: 50,
            searchFlag: searchValue,
            checkActive,
            checkVerified,
            checkSocialAuthed
        }
        props.filterCustomer(request,()=>{},()=>{})
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'customer Id', width: 130 },
        {
            field: 'firstName',
            headerName: 'Customer Name',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.firstName+" "+params.row.lastName}`,
        },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phoneNumber', headerName: 'Phone Number', width: 180 },
        
        {
            field: 'active',
            headerName: 'Active User',
            valueGetter: (params: GridValueGetterParams) => params.row.active ? "Yes" : "No",
        },
        {
            field: 'verified',
            headerName: 'Verified User',
            valueGetter: (params: GridValueGetterParams) => params.row.verified ? "Yes" : "No",
        },
        {
            field: 'usedSocialAuth',
            headerName: 'Social Auth User',
            valueGetter: (params: GridValueGetterParams) => params.row.usedSocialAuth ? "Yes" : "No",
        },
    ];

    return (
        <PageContainer
            navBarTitle={"Customers"}
        >
            <PageContentContainer>
                {width&&width>=1024&&<Text>Customers</Text>}
                <SearchContainer>
                    <InputField
                        icon={<FiSearch size={30} color={COLORS.orange} />}
                        placeholder="Search by Customer parameters"
                        onChange={(value: string)=>setSearchString(value)}
                        submit={()=>submitSearch()}
                        containerStyles={{ marginBottom: width&&width<600 ? '20px' : 0, width: '100%', maxWidth: '350px', marginRight: width&&width<600 ? 0 : 20}}
                    />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        maxWidth: 335
                    }}>
                        <Button
                            icon={<FiFilter size={25} color={COLORS.border} style={{ marginRight: 10 }} />}
                            label="Filter"
                            containerStyles={{
                                width: 160,
                                display: 'flex',
                                justifyContent: 'center',
                                border: `2px solid ${COLORS.border}`,
                                backgroundColor: "#fff",
                                color: COLORS.border,
                                marginRight: width&&width<600 ? 0 : 20
                            }}
                        />
                        <Button
                            icon={<FiBarChart size={25} color={COLORS.border} style={{ marginRight: 10 }} />}
                            label="Sort"
                            containerStyles={{
                                width: 160,
                                display: 'flex',
                                justifyContent: 'center',
                                border: `2px solid ${COLORS.border}`,
                                backgroundColor: "#fff",
                                color: COLORS.border
                            }}
                        />
                    </div>
                </SearchContainer>

                <div
                    style={{
                        marginTop: 10,
                        borderBottom: `2px solid ${COLORS.border}`,
                        paddingBottom: '20px'
                    }}
                >
                    <TabBar
                        tabs={["All", "Active", "InActive"]}
                        onClick={(status: string)=>{
                            if(status==='Active'){
                                filter(true,true,false,false)
                            }else if(status==='InActive'){
                                filter(false,true,false,false)
                            } {
                                handleGetCustomerList()
                            }
                           
                        }}
                    />
                </div>

                <div style={{
                    height: '100%',
                    width: '100%'
                }}>
                    <DataGrid
                        rows={props.customers.customerList}
                        columns={columns}
                        getRowId={(row) => row.id}
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids)
                            const selectedRowData: [Customer] = props.customers.customerList.filter((customer: Customer) =>
                                selectedIds.has(customer.id)
                            );
                            handleClickCustomer(selectedRowData[0].id)
                        }}
                        pagination
                    />

                </div>

            </PageContentContainer>
        </PageContainer>
    )
};

let PageContentContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

let SearchContainer = styled.div`
    border-bottom: 2px solid ${COLORS.border};
    padding-bottom: 20px;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

let CustomerListContainer = styled.div`
    margin-top: 20px;
    overflow-y: scroll;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        /* justify-content: center; */
        grid-template-columns: repeat(auto-fit, 350px);
    }
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        grid-template-columns: repeat(auto-fit, 380px);
    }
`;

let Text = styled.p`
    font-size: 32px;
    color: ${COLORS.black};
    font-weight: 500;
    margin-bottom: 15px;
`;

let StepperContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

function mapStateToProps(state: any) {
    return { 
        customers: state.customers
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        getCustomerList: (data: {pageNo: number, pageSize: number}, onSuccess: Function, onFailure: Function) => {
            dispatch(getCustomerList(data, onSuccess, onFailure));
        },
        searchCustomer: (request: CustomerSearchRequest, onSuccess: Function, onFailure: Function) => {
            dispatch(getCustomerSearchList(request, onSuccess, onFailure));
        },
        filterCustomer: (request: CustomerFilterRequest, onSuccess: Function, onFailure: Function) => {
            dispatch(getCustomerFilterList(request, onSuccess, onFailure));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerList);