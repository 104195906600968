import {addCustomerList, addCustomerError, addCustomer, addCustomerSearchList} from '../../slices/customerSlices'
import APIService from '../../../api/api'
import { CustomerFilterRequest, CustomerSearchRequest } from '../../../types/customer/customerInterface';

export function getCustomerList (queryParam: {pageNo: number, pageSize: number}, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getCustomerList(queryParam.pageNo, queryParam.pageSize)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addCustomerList({
                        customers: response.response.customers,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess(response.response)
                }else{
                    dispatch(addCustomerError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addCustomerError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getCustomerSearchList (request: CustomerSearchRequest, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.customerSearch(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addCustomerSearchList({
                        customers: response.response.content,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess()
                }else{
                    dispatch(addCustomerError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addCustomerError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getCustomerFilterList (request: CustomerFilterRequest, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.customerFilter(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addCustomerSearchList({
                        customers: response.response.content,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess()
                }else{
                    dispatch(addCustomerError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addCustomerError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getCustomerById (customerId: number, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getCustomerById(customerId)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addCustomer({
                        customer: response.response.data}))
                    onSuccess(response.response.data)
                }else{
                    dispatch(addCustomerError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addCustomerError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}