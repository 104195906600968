import {addProduct, addProductError, addProductList,updateProduct,updatedProductImages, addSearchProductList} from '../../slices/productSlice'
import APIService from '../../../api/api'
import {ProductFilterRequest, ProductInterface, ProductRequestObjectInterface, ProductSearchRequestModel} from '../../../types/product/ProductInterface'

export function createNewProduct(data: ProductRequestObjectInterface, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.createNewProduct(data)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("201")){
                    dispatch(addProduct(response.response.data))
                    onSuccess(response.response.data)
                }else{
                    dispatch(addProductError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addProductError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function updateProductInfo(data: ProductInterface, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.updateProduct(data)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(updateProduct({
                        productId: data.productId,
                        product: response.response.data
                    }))
                    onSuccess(response.response.data)
                }else{
                    dispatch(addProductError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addProductError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getProductList (queryParam: {pageNo: number, pageSize: number}, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getProductList(queryParam.pageNo, queryParam.pageSize)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addProductList({
                        products: response.response.products,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess(response.response)
                }else{
                    dispatch(addProductError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addProductError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getProductSearchResults (request: ProductSearchRequestModel, onSuccess?: Function, onError?: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.productSearch(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addSearchProductList({
                        products: response.response.content,
                        pageNo: response.response.pageNo
                    }))
                    if(onSuccess)onSuccess()
                }else{
                    dispatch(addProductError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    if(onError)onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addProductError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getProductFilterResults (request: ProductFilterRequest, onSuccess?: Function, onError?: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.fitlerProducts(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addSearchProductList({
                        products: response.response.content,
                        pageNo: response.response.pageNo
                    }))
                    if(onSuccess)onSuccess()
                }else{
                    dispatch(addProductError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    if(onError)onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addProductError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getProductListByArray (ids: Array<number>, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getProductsByIDList(ids)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    // dispatch(addProductListNoPageNumber({
                    //     products: response.response.data
                    // }))
                    onSuccess(response.response.data)
                }else{
                    dispatch(addProductError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addProductError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function uploadProductImages (productId: number,request: FormData, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.uploadProductImages(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(updatedProductImages({
                        productId: productId,
                        images: response.response
                    }))
                    onSuccess(response.response)
                }else{
                    dispatch(addProductError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addProductError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}