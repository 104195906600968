import React, { CSSProperties, FC, ReactElement } from 'react';
import styled from 'styled-components';

import COLORS from '../../assets/Colors';

interface DropdownFieldInterface {
    containerStyles?: CSSProperties;
    dropdownStyles?: CSSProperties;
    dropdownOptions: Array<string> | any;
    placeholder?: string;
    value?: string;
    onChange?: Function;
    icon?: ReactElement;
    label?: string;
    disabled?: boolean;
}

let DropdownField: FC<DropdownFieldInterface> = (props) => {
    return (
        <Container
            style={props.containerStyles}
        >
            {props.label&&<Text>{props.label}</Text>}
            <FieldContainer
                style={{paddingLeft: props.icon? 10 : 20, backgroundColor: props.disabled ? COLORS.border : "#fff"}}
            >
                {props.icon? props.icon : null}
                <Select
                    style={{...props.dropdownStyles, backgroundColor: props.disabled ? COLORS.border : "#fff"}}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(event)=>{if(props.onChange)props.onChange(event.target.value)}}
                >
                    {!props.value&&<option value={""} key={100+"key"}>Choose here</option>}
                    {props.dropdownOptions.map((option: any, i: number) =>(
                            <option value={option} key={i+option+"key"}>{option}</option>
                        ))}
                </Select>
            </FieldContainer>

        </Container>
    )
}

let Container = styled.div`
    width: 300px;
`;

let FieldContainer = styled.div`
    height: 35px;
    border: 2px solid ${COLORS.border};
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

let Select = styled.select`
    width: 100%;
    font-size: 16px;    
    color: ${COLORS.grey1};
    border-style: none;
    padding-left: 10px;
    &:focus {
        outline: 0;
    }
`;

let Text = styled.p`
    font-size: 16px;
    color: ${COLORS.black};
    font-weight: 400;
    margin-bottom: 5px;
`;

export default DropdownField;