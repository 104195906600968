import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiSearch, FiFilter, FiBarChart } from 'react-icons/fi';

import PageContainer from '../components/universal/PageContainer'
import TabBar from '../components/universal/TabBar'
import InputField from '../components/Form/InputField';
import COLORS from '../assets/Colors';
import useWindowDimensions from '../utils/useWindowDimensions';
import Button from '../components/Form/Button';
import ProductCard from '../components/cards/ProductCard'
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import { useNavigate } from 'react-router-dom';
import { getProductFilterResults, getProductList, getProductSearchResults } from '../redux/thunks/product/productThunk'
import { connect } from 'react-redux';
import { ProductFilterRequest, ProductInterface, ProductSearchRequestModel } from '../types/product/ProductInterface';
import ModalContainer from '../components/modal/ModalContainer';
import ProductSearchFilterModal from '../components/modal/ProductSearchFilterModal';
import PageStepper from '../components/universal/PageSteper';
import DropdownField from '../components/Form/dropdownField';
import { format } from 'date-fns';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

let ProductList: FC<any> = (props) => {

    let { width, height } = useWindowDimensions()

    let authCheck = useAuthenticationCheck()
    let navigate = useNavigate();

    let [searchString, setSearchString] = useState("");
    let [searchFilterModalOpen, setSearchFilterModalOpen] = useState<boolean>(false);
    let [searchObject, setSearchObject] = useState<{ category: string, maxPrice: number, minPrice: number, listingStatus: string }>({
        category: '', maxPrice: 0, minPrice: 0, listingStatus: ''
    });

    let [pageNumber, setPageNumber] = useState(0);
    let [totalElements, setTotalElements] = useState(0);
    let [totalPages, setTotalPages] = useState(1);
    let [lastPage, setIsLastPage] = useState(true);
    let [pageSize, setPageSize] = useState(50);

    let updatePageNumber = (updatedPageNumber: number) => {
        setPageNumber(updatedPageNumber);
        handleGetProductList(pageNumber, pageSize);
    }


    useEffect(() => {
        authCheck()
        if (props.productInfo.products === undefined || props.productInfo.products.length == 0 || props.productInfo.products.length < 50) {
            handleGetProductList()
        }
    }, [])

    let handleGetProductList = (pageNo: number = 0, pageSize: number = 50) => {
        props.getProductList(
            { pageNo, pageSize },
            (response: any) => {
                setPageSize(response.pageSize)
                setTotalElements(response.totalElements)
                setTotalPages(response.totalPages)
                setIsLastPage(response.last)
            },
            () => {
                //TODO: show modal
            },
        )
    }

    let filter = (field: string, filterValue: boolean) => {
        let request: ProductFilterRequest = {
            pageNumber: 0,
            itemCount: 50,
            field: field,
            filterValue: filterValue
        }
        props.productFilter(request, () => { }, () => { })
    }

    let handleAddProduct = () => {
        navigate("/product/info?create_new_product=true")
    }

    let handleClickProduct = (productId: string | number) => {
        navigate(`/product/info?productId=${productId}`)
    }

    let submitSearch = () => {
        let request: ProductSearchRequestModel = {
            searchString: searchString,
            pageNumber: 0,
            itemCount: 50,
            searchFilter: searchObject
        }
        props.productSearch(request, () => { }, () => { })
    }

    const columns: GridColDef[] = [
        { field: 'productId', headerName: 'Product Id', width: 130 },
        { field: 'productName', headerName: 'Name', width: 200 },
        { field: 'category', headerName: 'Category', width: 130 },
        { field: 'quantity', headerName: 'Quantity', width: 130 },
        {
            field: 'isFeatured', headerName: 'Featured', width: 130,
            valueGetter: (params: GridValueGetterParams) => params.row.isFeatured ? "Yes" : "No"
        },
        {
            field: 'isListed', headerName: 'Listed', width: 130,
            valueGetter: (params: GridValueGetterParams) => params.row.isListed ? "Yes" : "No"
        },
        // {
        //     field: 'createdAt',
        //     headerName: 'Order Date',
        //     type: 'date',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) =>
        //         `${format(new Date(params.row.createdAt), 'EEE PP')}`,
        // },
        {
            field: 'productPrice',
            headerName: 'Unit Price',
            valueGetter: (params: GridValueGetterParams) =>
                `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.row.productPrice)}`,
        },
    ];

    return (
        <PageContainer
            navBarTitle={"Products"}
        >
            <PageContentContainer>
                {width && width >= 1024 && <Text>Products</Text>}
                <SearchContainer>
                    <InputField
                        icon={<FiSearch size={30} color={COLORS.orange} />}
                        onChange={(value: string) => setSearchString(value)}
                        submit={() => submitSearch()}
                        placeholder="Search by Product parameters"
                        containerStyles={{ marginBottom: width && width < 600 ? '20px' : 0, width: '100%', maxWidth: '350px', marginRight: width && width < 600 ? 0 : 20 }}
                    />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        maxWidth: 450
                    }}>
                        <Button
                            icon={<FiFilter size={25} color={COLORS.border} style={{ marginRight: 10 }} />}
                            label="Filter"
                            onClick={() => setSearchFilterModalOpen(true)}
                            containerStyles={{
                                width: 160,
                                display: 'flex',
                                justifyContent: 'center',
                                border: `2px solid ${COLORS.border}`,
                                backgroundColor: "#fff",
                                color: COLORS.border,
                                marginRight: width && width < 600 ? 0 : 20
                            }}
                        />

                        <Button
                            // icon={<FiBarChart size={25} color={COLORS.border} style={{ marginRight: 10 }} />}
                            label="Add Product"
                            containerStyles={{
                                width: 160,
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: COLORS.blue,
                                color: "#fff"
                            }}
                            onClick={handleAddProduct}
                        />
                    </div>
                </SearchContainer>

                <div
                    style={{
                        marginTop: 10,
                        borderBottom: `2px solid ${COLORS.border}`,
                        paddingBottom: '20px'
                    }}
                >
                    <TabBar
                        tabs={["All", "Listed", "DeListed", "Featured"]}
                        onClick={(field: string) => {
                            if (field == 'Listed' || field == 'DeListed') {
                                filter('listed', field === 'Listed' ? true : false)
                            } else if (field == 'Featured') {
                                filter('featured', true)
                            } else {
                                handleGetProductList()
                            }

                        }}
                    />
                </div>

                {/* <ProductListContainer>
                    {props.productInfo.products.map((data: ProductInterface, index: number) => (
                        <ProductCard
                            key={"product" + index}
                            productId={data.productId}
                            productName={data.productName}
                            unitPrice={data.productPrice}
                            quantity={data.quantity}
                            category={data.category}
                            featured={data.isFeatured ? data.isFeatured.toString() : "false"}
                            listed={data.isListed ? data.isListed.toString() : 'false'}
                            containerStyles={{ marginBottom: '20px' }}
                            onClick={() => handleClickProduct(data.productId)}
                            productImage={data.productImage.length > 0 ? data.productImage[0].imageUrl : null}
                        />
                    ))}
                </ProductListContainer> */}

                <div style={{
                    height: '100%',
                    width: '100%'
                }}>
                    <DataGrid
                        rows={props.productInfo.products}
                        columns={columns}
                        getRowId={(row) => row.productId}
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids)
                            const selectedRowData: [ProductInterface] = props.productInfo.products.filter((product: ProductInterface) =>
                                selectedIds.has(product.productId)
                            );
                            handleClickProduct(selectedRowData[0].productId)
                        }}
                        pagination
                    />

                </div>

            </PageContentContainer>

            <ModalContainer visible={searchFilterModalOpen}>
                <ProductSearchFilterModal
                    closeModal={() => setSearchFilterModalOpen(!searchFilterModalOpen)}
                    onMinValueChange={(value: number) => setSearchObject({ ...searchObject, minPrice: value })}
                    onMaxValueChange={(value: number) => setSearchObject({ ...searchObject, maxPrice: value })}
                    onCategoryChange={(value: string) => setSearchObject({ ...searchObject, category: value })}
                    onFilterSubmit={() => {
                        submitSearch();
                        setSearchFilterModalOpen(false);
                    }}
                    onStatusChange={(value: string) => setSearchObject({ ...searchObject, listingStatus: value })}
                    searchCategory={searchObject.category}
                    minPrice={searchObject.minPrice}
                    maxPrice={searchObject.maxPrice}
                    status={searchObject.listingStatus}
                />
            </ModalContainer>

        </PageContainer>
    )
};

let PageContentContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

let SearchContainer = styled.div`
    border-bottom: 2px solid ${COLORS.border};
    padding-bottom: 20px;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

let ProductListContainer = styled.div`
    margin-top: 20px;
    overflow-y: scroll;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        /* justify-content: center; */
        grid-template-columns: repeat(auto-fit, 350px);
    }
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        grid-template-columns: repeat(auto-fit, 380px);
    }
`;

let Text = styled.p`
    font-size: 32px;
    color: ${COLORS.black};
    font-weight: 500;
    margin-bottom: 15px;
`;

let StepperContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;


function mapStateToProps(state: any) {
    return {
        productInfo: state.products
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        getProductList: (data: { pageNo: number, pageSize: number }, onSuccess: Function, onFailure: Function) => {
            dispatch(getProductList(data, onSuccess, onFailure));
        },
        productSearch: (request: ProductSearchRequestModel, onSuccess?: Function, onFailure?: Function) => {
            dispatch(getProductSearchResults(request, onSuccess, onFailure))
        },
        productFilter: (request: ProductFilterRequest, onSuccess?: Function, onFailure?: Function) => {
            dispatch(getProductFilterResults(request, onSuccess, onFailure))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductList);
