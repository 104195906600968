import { FC } from 'react';
import styled from "styled-components";

import COLORS from '../../assets/Colors';
import Button from '../Form/Button';
import InputField from '../Form/InputField';
import ImageUploader from 'react-images-upload';
import CheckableText from '../Form/CheckableText';

interface CampaignModalInterface {
    // message: string;
    onClickConfirm: Function;
    onClickCancel: Function;
    campaignCode: string;
    productId: number;
    active: boolean;
    onDrop: any;
    setCampaignCode: Function;
    setProductId: Function;
    setActive: Function
    update: boolean;
}

let CampaignModal: FC<CampaignModalInterface> = (props) => {

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text style={{ marginBottom: "15px", textAlign: "center", fontWeight: '500', fontSize: "18px" }}>Campaign</Text>
                <InputField
                    label="Campaign Code"
                    value={props.campaignCode}
                    onChange={(val: string) => props.setCampaignCode(val)}
                    containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                />
                <InputField
                    label="Product Id"
                    value={props.productId}
                    onChange={(val: string) => props.setProductId(val)}
                    containerStyles={{ width: '100%', maxWidth: '390px', marginBottom: 15 }}
                />
                <CheckableText
                    label="Active Campaign"
                    value={props.active}
                    toggle={(val: boolean) => props.setActive(val)}
                    containerStyles={{ marginBottom: 15 }}
                />
                <ImageUploader
                    {...props}
                    withIcon={true}
                    onChange={props.onDrop}
                    imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                    maxFileSize={5242880}
                    withPreview={true}
                />
                <ButtonContainer>
                    <Button
                        label="Cancel"
                        containerStyles={{
                            width: 150,
                            justifyContent: 'center',
                            border: `2px solid ${COLORS.border}`,
                            backgroundColor: "#fff",
                            color: COLORS.black,
                        }}
                        onClick={props.onClickCancel}
                    />
                    <Button
                        label={props.update ? "Update" : "Create"}
                        containerStyles={{ width: 150 }}
                        onClick={props.onClickConfirm}
                    />
                </ButtonContainer>
            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    align-items: center;
    justify-content: center;
`;

let ModalContainer = styled.div`
    min-width: 300px;
    max-width: 380px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: 0px 0px 26px 0px rgba(217,217,217,1);
    -moz-box-shadow: 0px 0px 26px 0px rgba(217,217,217,1);
    box-shadow: 0px 0px 26px 0px rgba(217,217,217,1);
`;

let Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${COLORS.black};
`;

let ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
`;

export default CampaignModal;