import React, { CSSProperties, FC, useState } from 'react';
import { FiDisc, FiXCircle } from 'react-icons/fi';

import styled from 'styled-components';
import COLORS from '../../assets/Colors';
import Button from './Button';
import DropdownField from './dropdownField';

interface PointListInterface {
    label: string;
    containerStyles?: CSSProperties;
    edit: boolean;
    list: Array<string>;
    updateList?: Function;
    textFieldStyles?: CSSProperties;
    inputStyles?: CSSProperties;
    useDropdown?: boolean;
    dropdownList?: Array<string>;
    removeListItem?: Function;
}

let PointList: FC<PointListInterface> = (props) => {
    let [fieldValue, setFieldValue] = useState("");

    let removeListItem = (item: string) => {
        let newList = props.list.filter((element: string) => element!==item)
        if(props.removeListItem){
            props.removeListItem([...newList])
        }
    }

    return (
        <Container>
            <Text style={{marginBottom: '15px', fontWeight: '500' }}>{props.label}</Text>
            {props.list.map((item, index) => (
                <Row
                    key={"point"+index}
                >
                    {
                        props.edit ? 
                        <FiXCircle size={25} color={COLORS.red} style={{ marginRight: 10, minWidth: 25 }} onClick={()=>removeListItem(item)}/>
                        : <FiDisc size={25} color={COLORS.grey2} style={{ marginRight: 10, minWidth: 25 }} />
                    }
                    <Text style={{color: COLORS.grey2}}>{item}</Text>
                </Row>
            ))}
            {props.edit&&!props.useDropdown&&<FieldContainer
                style={{...props.inputStyles}}
            >
                <Input
                    style={{...props.textFieldStyles}}
                    type={"text"}
                    value={fieldValue}
                    onChange={event=>setFieldValue(event.target.value)}
                />
            </FieldContainer>}

            {props.edit&&props.useDropdown&&<DropdownField
                    containerStyles={{width: '100%', marginBottom: 15 }}
                    value={fieldValue}
                    onChange={setFieldValue}
                    dropdownOptions={props.dropdownList ? props.dropdownList : []}
                />}
            {props.edit&&<Button
                label="Add Point"
                containerStyles={{width: '100%'}}
                onClick={()=>{
                    if(props.updateList){
                        props.updateList(fieldValue)
                        setFieldValue('')
                    }
                    
                }}
            />}
        </Container>
    )
}

let Container = styled.div`
    margin-bottom: 15px;
`;

let Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

let Text = styled.p`
    font-size: 16px;
    color: ${COLORS.black};
    font-weight: 400;
`;

let Input = styled.input`
    width: 100%;
    font-size: 16px;    
    color: ${COLORS.grey1};
    border-style: none;
    padding-left: 10px;
    &:focus {
        outline: 0;
    }
`;

let FieldContainer = styled.div`
    height: 35px;
    border: 2px solid ${COLORS.border};
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
`;


export default PointList;