import styled from "styled-components";

import COLORS from '../../assets/Colors';
import { CircularProgress } from '@mui/material';


let LoadingModal = () => {

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text style={{marginBottom: "15px", textAlign: "center", fontWeight: '500', fontSize: "18px"}}>Loading</Text>
                <Text style={{marginBottom: "15px"}}>Please be patient while we finish loading</Text>

                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress size={85}/>
                </div>
            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    /* position: fixed; */
    align-items: center;
    justify-content: center;
`;

let ModalContainer = styled.div`
    min-width: 300px;
    max-width: 380px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
`;

let Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${COLORS.black};
`;

export default LoadingModal;