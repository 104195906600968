import { createSlice } from '@reduxjs/toolkit'
import { SaleDetail, SaleModel } from '../../types/sale-pos/saleInterface'

const initialState = {
    salesList: new Array<SaleModel>(),
    saleDetailList: new Array<SaleDetail>(),
    error: null,
    pageNo: 0
}

const saleSlice = createSlice({
    name: `sales`,
    initialState,
    reducers:{
        addSale(state, action) {
            state.salesList.push(action.payload.sale)
            state.saleDetailList = action.payload.saleDetails
            return state
        },
        addSalesList(state, action){
            return {...state, salesList: [...action.payload.sales], error: null, pageNo: action.payload.pageNo, saleDetails: []}
        },
        updateSale(state, action){
            return {
                ...state,
                salesList: state.salesList.map((item: SaleModel)=>
                    item.saleId === action.payload.saleId ? {...item, ...action.payload.sale} : item),
                error: null,
            }
        },
        addSaleError(state, action) {
            return {
                ...state,
                error: action.payload.error
            }
        },
        addSaleDetails(state, action){
            return {
                ...state,
                saleDetailList: action.payload
            }
        },

        deleteSaleDetail(state, action){
            let index = state.saleDetailList.findIndex(item=> item.saleDetailId === action.payload.saleDetailId)
            state.saleDetailList.splice(index,1)
        },
        updateSaleDetailQuantity(state, action){
            let index = state.saleDetailList.findIndex(item=> item.saleDetailId === action.payload.saleDetailId);
            state.saleDetailList[index].quantity = action.payload.quantity;
            // return {...state}
        }

    }
})

export const {
    addSale,
    addSalesList,
    updateSale,
    addSaleError,
    addSaleDetails,
    deleteSaleDetail,
    updateSaleDetailQuantity
} = saleSlice.actions;

export default saleSlice.reducer;