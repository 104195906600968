import React, { FC } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../../utils/useWindowDimensions';
import NavbarDesktop from './NavbarDesktop';
import NavbarSmall from './NavbarSmall';

interface PageContainerInterface {
    navBarTitle: string;
}

let PageContainer:FC<PageContainerInterface> = (props) => {

    let {width, height} = useWindowDimensions()

    if(width&&width<1280) return (
        <PageContainerMobile>
            <NavbarSmall 
                title={props.navBarTitle}
            />
            <div style={{display: 'flex', overflow: 'scroll', height: '100%'}}>
                {props.children}
            </div>
        </PageContainerMobile>
    )

    return (
        <PageContainerDesktop>
            <NavbarDesktop/>
            <div style={{display: 'flex', overflow: 'scroll', width: '100%'}}>
                {props.children}
            </div>
        </PageContainerDesktop>
    )
};

let PageContainerMobile = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
`;

let PageContainerDesktop = styled.div`
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: row;
`;


export default PageContainer;