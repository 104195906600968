let COLORS = {
    background: "#F8F8F9",
    border: "#D5D5D5",
    grey1: "#9E9E9E",
    grey2: "#656565",
    blue: "#00A7E1",
    black: "#1C212D",
    orange: "#F89406",
    green: '#009B72',
    red: "#FB3640"
}

export default COLORS;