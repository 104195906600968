import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import store from "../redux/store"

export let useAuthenticationCheck = () => {
    let navigate = useNavigate();

    let {authToken,authenticated} = store.getState().auth

    let checkAuth = () => {
        if(authToken===null || authenticated===false){
            navigate("/auth/login")
        }
    }

    return checkAuth
}