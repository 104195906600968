import { createSlice } from '@reduxjs/toolkit'
import { CampaignModel } from '../../types/campaign/campaignInterfaces'

const initialState = {
    campaignList: new Array<CampaignModel>(),
    error: null,
    pageNo: 0
}

const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        addCampaignList(state, action) {
            return {...state,campaignList: action.payload.campaigns, pageNo: action.payload.pageNo, error: null}
        },
        updateCampaign(state, action) {
            let itemIndex = 0;
            let temp: any = state.campaignList.find((item,index)=> {
                if(item.campaignId === action.payload.campaignId){
                    itemIndex = index;
                    return item
                }
            });
            if(temp) {
                let tempState = [...state.campaignList]
                tempState.splice(itemIndex, 1);
                temp = {...action.payload}
                tempState.push(temp)
                return {...state, campaignList: [...tempState]}
            }

            return state;
        },
        addCampaign(state, action) {
            return {...state, campaignList: [...state.campaignList, action.payload.campaign], error: null}
        },
        addCampaignError(state, action) {
            return {...state, error: action.payload.error}
        }
    }
})

export const {addCampaign,addCampaignList, addCampaignError, updateCampaign} = campaignSlice.actions;
export default campaignSlice.reducer;