import React, { FC, useEffect, useState } from 'react';
import { FiEdit3, FiXCircle } from 'react-icons/fi';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format, formatDistance } from 'date-fns'

import COLORS from '../assets/Colors';
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import PageContainer from '../components/universal/PageContainer'
import LabeledText from '../components/Form/LabeledText'
import OrderStatus from '../components/Form/OrderStatus'
import ProductCard from '../components/cards/ProductCard';
import Button from '../components/Form/Button';
import ModalContainer from '../components/modal/ModalContainer'
import OrderStatusModal from '../components/modal/OrderStatusModal';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import { Order, OrderDetail, OrderUpdateRequest, Payment } from '../types/order/orderInterface';
import { Customer, CustomerShippingAddress } from '../types/customer/customerInterface';
import { getOrderDetailList, updateOrderInfo } from '../redux/thunks/order/orderThunk'
import { ProductInterface } from '../types/product/ProductInterface';
import { getProductListByArray } from '../redux/thunks/product/productThunk'
import { getCustomerById } from '../redux/thunks/customer/customerThunk'
import { Staff, StaffRole } from '../types/staff/staffInterface';
import InputField from '../components/Form/InputField';
import PaymentUpdateModal from '../components/modal/PaymentUpdateModal';
import OrderProductCard from '../components/cards/OrderProductCard';

let OrderInfo: FC<any> = (props) => {

    let [editOrderInfo, setEditOrderInfo] = useState(false);
    let [editPaymentInfo, setEditPaymentInfo] = useState(false);
    let [editDeliveryInfo, setEditDeliveryInfo] = useState(false);
    let [editOrderItemInfo, setEditOrderItemInfo] = useState(false);
    let [orderDetails, setOrderDetails] = useState<Array<OrderDetail>>([]);
    let [orderProducts, setOrderProducts] = useState<Array<ProductInterface>>([]);
    let [primaryAddress, setPrimaryAddress] = useState<CustomerShippingAddress>();

    let [updateStatus, setUpdateStatus] = useState("");

    let [order, setOrder] = useState<Order>({
        id: 0,
        customer: {
            id: 0,
            active: false,
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            socialAuthProvider: '',
            usedSocialAuth: false,
            verified: false
        },
        status: '',
        orderDate: '',
        orderTotal: 0,
        staff: {
            staffId: 0,
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            password: '',
            permission: [],
            staffRole: {
                roleId: 0,
                roleName: '',
                roleDescription: ''
            },
            active: false
        },
        payment: undefined,
        orderMethod: '',
        trackingNumber: undefined,
        orderDetails: [],
        address: null
    });

    let [customer, setCustomer] = useState<Customer>({
        id: 0,
        active: false,
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        socialAuthProvider: "",
        usedSocialAuth: false,
        verified: false
    })

    let [payment, setPayment] = useState<Payment>({
        paymentType: "",
        paymentDate: new Date().toISOString().substring(0, 10),
        paymentAmount: order.orderTotal
    });

    let [isOrderStatusModalVisisble, setOrderStatusModalVisible] = useState(false)
    let [isPaymentModalVisible, setPaymentModalVisible] = useState(false);
    let authCheck = useAuthenticationCheck()
    const [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate();

    useEffect(() => {
        authCheck()
    }, [])

    useEffect(() => {
        if (searchParams.get("orderId") !== null) {
            let queryOrderId = searchParams.get("orderId") || ""
            if (queryOrderId !== "") findOrderInState(parseInt(queryOrderId))
        }
    }, [])

    let findOrderInState = (orderId: number) => {
        let result: Order = props.orders.orderList.find((item: Order) => item.id == orderId);
        if (result !== null) {
            loadOrderInfo(result)
            // getCustomerData(result.customer)
            // getOrderDetail(result.id)
        }
    }

    let loadOrderInfo = (orderInfo: Order) => {
        setOrder(orderInfo)
    }

    let getCustomerData = (customerId: number) => {
        let customerData: Customer = props.customers.customerList.find((data: Customer) => data.id == customerId);
        if (customerData) {
            setCustomer(customerData)
            if (customerData.customerShippingAddressList) {
                let address = customerData.customerShippingAddressList.find(data => data.isPrimary === true)
                if (address) setPrimaryAddress(address)
            }

        } else {
            props.getCustomer(customerId,
                (customerResponse: Customer) => {
                    setCustomer(customerResponse)
                    if (customerResponse.customerShippingAddressList) {
                        let address = customerResponse.customerShippingAddressList.find(data => data.isPrimary === true)
                        if (address) setPrimaryAddress(address)
                    }
                },
                () => {
                    //TODO add modal
                }
            )
        }
    }

    let handleUpdateOrder = () => {
        let updateRequest: OrderUpdateRequest = {
            id: order.id,
            customer: order.customer.id,
            status: updateStatus,
            orderDate: order.orderDate,
            orderTotal: order.orderTotal,
            staff: undefined,
            payment: null,
            orderMethod: order.orderMethod,
            trackingNumber: order.trackingNumber
        }
        props.updateOrder(updateRequest,
            (orderResponse: Order) => {
                setOrder(orderResponse)
            },
            () => {
                //TODO add modal
            }
        )
        setOrderStatusModalVisible(false)
    }

    let handleClickProduct = (productId: string | number) => {
        navigate(`/product/info?productId=${productId}`)
    }

    return (
        <PageContainer
            navBarTitle={"Orders"}
        >
            <PageContentContainer>

                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Order Info</Text>
                        {editOrderInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditOrderInfo(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditOrderInfo(order.status !== 'CANCELED')} />}
                    </div>
                    <SectionContent>
                        <LabeledText
                            label={"Order ID"}
                            value={order.id.toString()}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <LabeledText
                            label={"Customer ID"}
                            value={order.customer.id}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <LabeledText
                            label={"Customer Name"}
                            value={order.customer.firstName + " " + order.customer.lastName}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <LabeledText
                            label={"Phone Number"}
                            value={order.customer.phoneNumber}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <LabeledText
                            label={"Customer Email"}
                            value={order.customer.email}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <LabeledText
                            label={"Order Date"}
                            value={order.orderDate ? format(new Date(order.orderDate), 'EEE PPpp') : ''}
                            // value={new Date(order.orderDate).toLocaleDateString()}
                            containerStyles={{ marginBottom: 15 }}
                        />

                        <LabeledText
                            label={"Order Date Count"}
                            value={order.orderDate ? formatDistance(new Date(order.orderDate), Date.now(), { addSuffix: true }) : ''}
                            // value={new Date(order.orderDate).toLocaleDateString()}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <OrderStatus
                            label={"Order Status"}
                            status={order.status}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <LabeledText
                            label={"Order Completed by"}
                            value={order.staff ? order.staff.firstName + " " + order.staff.lastName : "-none-"}//TODO
                            containerStyles={{ marginBottom: 15 }}
                        />

                        {
                            !editOrderInfo ?
                                <LabeledText
                                    label={"Tracking Number"}
                                    value={order.trackingNumber ? order.trackingNumber : "-none-"}//TODO
                                    containerStyles={{ marginBottom: 15 }}
                                /> :
                                <InputField
                                    label={"Tracking Number"}
                                    disabled={!editOrderInfo}
                                    value={order.trackingNumber ? order.trackingNumber : "-none-"}
                                    onChange={(val: string) => {
                                        setOrder({ ...order, trackingNumber: val })
                                    }}
                                    containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                                />
                        }

                    </SectionContent>
                    {editOrderInfo && <Button
                        label="Done"
                        containerStyles={{ width: '100%', maxWidth: 380, marginBottom: '20px', backgroundColor: COLORS.green }}
                        onClick={() => {
                            handleUpdateOrder();
                            setEditOrderInfo(false);
                        }}
                    />}
                    <Button
                        label="Update Order Status"
                        disabled={order.status === 'CANCELED'}
                        containerStyles={{ width: '100%', maxWidth: 380, marginBottom: '20px' }}
                        onClick={() => setOrderStatusModalVisible(true)}
                    />
                </SectionContainer>

                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Payment Info</Text>
                        {editPaymentInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditPaymentInfo(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditPaymentInfo(order.status !== 'CANCELED')} />}
                    </div>
                    <SectionContent>
                        <LabeledText
                            label={"Order Total"}
                            value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(order.orderTotal)}//TODO 
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <LabeledText
                            label={"Payment Method"}
                            value={order.payment ? order.payment.paymentType : "-none-"}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <LabeledText
                            label={"Payment Status"}
                            value={order.payment == null ? "incomplete" : "completed"}
                            containerStyles={{ marginBottom: 15 }}
                        />
                    </SectionContent>
                </SectionContainer>

                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Delivery Info</Text>
                        {editDeliveryInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditDeliveryInfo(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditDeliveryInfo(order.status !== 'CANCELED')} />}
                    </div>
                    <SectionContent>
                        <LabeledText
                            label={"Delivery Method"}
                            value={order.orderMethod}
                            containerStyles={{ marginBottom: 15 }}
                        />

                        {order.address&&<>
                           {order.address.address_line_1 && <LabeledText
                                label={"Address Line 1"}
                                value={order.address ? order.address.address_line_1 : "-none-"}
                                containerStyles={{ marginBottom: 15 }}
                            />}

                            {order.address.address_line_2 && <LabeledText
                                label={"Address Line 2"}
                                value={order.address ? order.address.address_line_2 : "-none-"}
                                containerStyles={{ marginBottom: 15 }}
                            />}

                            {order.address.city && <LabeledText
                                label={"City"}
                                value={order.address ? order.address.city : "-none-"}
                                containerStyles={{ marginBottom: 15 }}
                            />}

                            {order.address.parish_state_province && <LabeledText
                                label={"Parish"}
                                value={order.address ? order.address.parish_state_province : "-none-"}
                                containerStyles={{ marginBottom: 15 }}
                            />}

                            {order.address.postal_code && <LabeledText
                                label={"Postal Code"}
                                value={order.address ? order.address.postal_code : "-none-"}
                                containerStyles={{ marginBottom: 15 }}
                            />}

                            <LabeledText
                                label={"Address Type"}
                                value={order.address ? (
                                    order.address.is_knutsford_address ? "Knutsford Express" :
                                    order.address.is_zipmail_address ? "Zipmail" : "Other"
                                ) : "-none-"}
                                containerStyles={{ marginBottom: 15 }}
                            />
                        </>}

                    </SectionContent>
                </SectionContainer>

                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Order Items</Text>
                        {editOrderItemInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditOrderItemInfo(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditOrderItemInfo(true)} />}
                    </div>
                    <SectionContent>
                        {order.orderDetails.map((data, index) => (
                            <OrderProductCard
                                key={"order-product-" + index}
                                productId={data.product.productId}
                                productName={data.product.productName}
                                orderPrice={data.total}
                                orderQuantity={data.quantity}
                                category={data.product.category}
                                containerStyles={{ marginBottom: '20px' }}
                                productImage={data.product.productImage.length > 0 ? data.product.productImage[0].imageUrl : null}
                                onClick={() => handleClickProduct(data.product.productId)}
                            />
                        ))}
                    </SectionContent>

                </SectionContainer>

            </PageContentContainer>

            <ModalContainer visible={isOrderStatusModalVisisble}>
                <OrderStatusModal
                    currentOrderStatus={updateStatus}
                    closeModal={() => setOrderStatusModalVisible(false)}
                    onChange={(status: string) => {
                        setUpdateStatus(status)
                    }}
                    onClickConfirm={handleUpdateOrder}
                />
            </ModalContainer>

            <ModalContainer visible={false}>
                <PaymentUpdateModal
                    closeModal={() => { }}
                    paymentObject={payment}
                    onChangePaymentObject={(val: Payment) => setPayment(val)}
                    onClickConfirm={() => { }}
                />
            </ModalContainer>

        </PageContainer>
    )
}

let PageContentContainer = styled.div`
    padding: 20px;
    width: 100%;
`;

let SectionContainer = styled.div`
     @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        max-width: 720px;
     }
`;

let SectionContent = styled.div`
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: auto;
        grid-auto-flow: row;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 350px);
    }
`;

let Text = styled.p`
    font-size: 24px;
    color: ${COLORS.black};
    font-weight: 500;
`;

function mapStateToProps(state: any) {
    return {
        orders: state.orders,
        customers: state.customers,
        products: state.products
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        retrieveOrderDetails: (orderId: number, onSuccess: Function, onFailure: Function) => {
            dispatch(getOrderDetailList(orderId, onSuccess, onFailure));
        },
        getOrderProducts: (ids: Array<number>, onSuccess: Function, onFailure: Function) => {
            dispatch(getProductListByArray(ids, onSuccess, onFailure));
        },
        getCustomer: (customerId: number, onSuccess: Function, onFailure: Function) => {
            dispatch(getCustomerById(customerId, onSuccess, onFailure));
        },
        updateOrder: (order: OrderUpdateRequest, onSuccess: Function, onFailure: Function) => {
            dispatch(updateOrderInfo(order, onSuccess, onFailure))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderInfo);