import { updateAuth, updateAuthError } from '../../../redux/slices/authSlices'
import APIService from '../../../api/api'

export function userLogin(data: { email: string, password: string }, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.loginRequest(data.email, data.password)
            .then(
                (response) => {
                    console.log(response)
                    if (response.statusCode.includes("200")) {
                        dispatch(updateAuth({
                            authenticated: true,
                            authToken: response.response.access_token
                        }))

                        onSuccess()
                    } else {
                        dispatch(updateAuthError({
                            error: {
                                message: response.message,
                                status: response.statusCode
                            }
                        }))
                        onError(response.message)
                    }
                }
            )
            .catch(error => {
                console.error(error)
                dispatch(updateAuthError({
                    error: {
                        message: "Server Error",
                        status: "500"
                    }
                }))
            })
    }
}

export function refreshAuthToken(onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.refreshAuthToken()
            .then(
                (response) => {
                    console.log(response)
                    if (response.statusCode.includes("200")) {
                        dispatch(updateAuth({
                            authenticated: true,
                            authToken: response.response.access_token
                        }))

                        onSuccess()
                    } else {
                        dispatch(updateAuthError({
                            error: {
                                message: response.message,
                                status: response.statusCode
                            }
                        }))
                        onError()
                    }
                }
            )
            .catch(error => {
                console.error(error)
                dispatch(updateAuthError({
                    error: {
                        message: "Server Error",
                        status: "500"
                    }
                }))
            })
    }
}