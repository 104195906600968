import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';

import COLORS from '../../assets/Colors';
import {processing,unfulfilled,fulfilled,canceled} from '../../constants/OrderStatusConstants'

interface OrderStatusInterface {
    label: string;
    status: string;
    containerStyles?: CSSProperties;
}

let OrderStatus: FC<OrderStatusInterface> = (props) => {
    return (
        <Container style={props.containerStyles}>
            <Text>{props.label}</Text>
            <div style={{
                backgroundColor: props.status.toUpperCase() === processing ? "rgba(0, 167, 225, 0.5)" :
                    props.status.toUpperCase() === fulfilled ? "rgba(0, 155, 114, 0.5)" :
                        props.status.toUpperCase() === canceled ? "rgba(251, 54, 64, 0.5)" :
                            "rgba(248, 148, 6, 0.5)",
                borderRadius: 8,
                paddingLeft: 10, paddingRight: 10,
                maxWidth: 100
            }}>
                <Text style={{
                    color: props.status.toUpperCase() === processing ? COLORS.blue :
                        props.status.toUpperCase() === fulfilled ? COLORS.green :
                            props.status.toUpperCase() === canceled ? COLORS.red :
                                COLORS.orange,
                }}>{props.status.toUpperCase()}</Text>
            </div>
        </Container>
    )
}

let Container = styled.div`

`;

let Text = styled.p`
    font-size: 16px;
    color: ${COLORS.black};
    font-weight: 400;
`;

export default OrderStatus;