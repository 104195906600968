export let ProductCategoryConstants = {
    lights: "Headlight, Bulbs and Lights",
    wraps: "Vinyl, Wraps and Tints",
    bodyMod: "Body Modification and Addons",
    care: "Care and Maintenance",
    electronics: "Electronics"
}

export let LightingSubCategory = {
    headlight: "Headlight",
    bulbs: "Bulbs",
    lights: "Lights",
    other: "Other"
}

export let WrapSubCategory = {
    vinyl: "Vinyl",
    wraps: "Wraps",
    Tints: "Tints",
    other: "Other"
}

export let BodyModSubCategory = {
    body: "Body Modification",
    addons: "Addons",
    other: "Other"
}

export let CareSubCategory = {
    care: "Care",
    maintenance: "Maintenance",
    other: "Other"
}

export let ElectronicsSubCategory = {
    electronics: "Electronics",
    other: "Other"
}
