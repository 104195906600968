import React, { CSSProperties, FC, useState } from 'react';
import styled from 'styled-components';
import COLORS from '../../assets/Colors';

interface TabBarInterface {
    tabs: Array<string>;
    containerStyles?: CSSProperties;
    onClick?: Function;
}

let TabBar:FC<TabBarInterface> = (props) => {

    let [highlightedTab, setHighlighted] = useState(0);

    return (
        <Container style={props.containerStyles}>
            {props.tabs.map((item,index)=>(
                <TabItemContainer
                    key={"tab-"+index}
                    onClick={()=>{
                        setHighlighted(index)
                        if(props.onClick) props.onClick(item)
                    }}
                    style={highlightedTab===index ? {
                        borderBottom: `2px solid ${COLORS.orange}`
                    } : {}}
                >
                    <p style={{
                        color: COLORS.black,
                        fontSize: 18,
                        fontWeight: highlightedTab===index ? 500 : 400
                    }}>
                        {item}
                    </p>
                </TabItemContainer>
            ))}
        </Container>
    )
}

let Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover{
        cursor: pointer;
    }
`;

let TabItemContainer = styled.div`
    margin-right: 15px;
    padding-bottom: 10px;
`;

export default TabBar;