import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiEdit3, FiXCircle } from "react-icons/fi";
import { useSearchParams } from 'react-router-dom';

import COLORS from '../assets/Colors';
import useWindowDimensions from '../utils/useWindowDimensions';
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import PageContainer from '../components/universal/PageContainer'
import InputField from '../components/Form/InputField';
import DropdownField from '../components/Form/dropdownField';
import CheckableText from '../components/Form/CheckableText';
import PointList from '../components/Form/pointList';
import Button from '../components/Form/Button';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import { Customer } from '../types/customer/customerInterface';
import { connect } from 'react-redux';
// import { updateCustomerInfo, createCustomerInfo } from '../redux/thunks/customer/customerThunk';
import PasswordResetField from '../components/Form/ResetPasswordField';

let CustomerInfo: FC<any> = (props) => {
    let { width, height } = useWindowDimensions()

    let [editCustomerInfo, setEditCustomerInfo] = useState(false);
    let authCheck = useAuthenticationCheck()
    const [searchParams, setSearchParams] = useSearchParams();

    let [customer, setCustomer] = useState<Customer>({
        id: 0,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
        active: false,
        verified: false,
        socialAuthProvider: '',
        usedSocialAuth: false
    })

    let [password, setPassword] = useState<string | null>("");
    let [editPassword, setEditPassword] = useState<boolean>(false);

    useEffect(() => {
        authCheck()
    }, [])

    useEffect(() => {
        if (searchParams.get("customerId") !== null) {
            let queryOrderId = searchParams.get("customerId") || ""
            if (queryOrderId !== "") findCustomerInState(parseInt(queryOrderId))
        }
    }, [])

    useEffect(() => {
        if (searchParams.get("create_new_customer") !== null && searchParams.get("create_new_customer") === "true") {
            setEditPassword(true);
            setEditCustomerInfo(true)
        }
    }, [searchParams])

    let findCustomerInState = (customerId: number) => {
        let result: Customer = props.customers.customerList.find((item: Customer) => item.id == customerId);
        console.log(result);
        if (result !== null) setCustomer(result)
    }
    // let createUpdateCustomerInfo = () => {
    //     setEditPassword(false);
    //     setEditCustomerInfo(false)
    //     let request: CustomerRequest = {
    //         ...customer,
    //         password: null
    //     }

    //     if (password !== "") request.password = password;
    //     setPassword("")

    //     if (customer.customerId === 0) {
    //         props.createInfo(request,
    //             (createdCustomer: Customer) => {
    //                 setCustomer(createdCustomer)
    //                 setSearchParams({ "create_new_customer": "false" })
    //             },
    //             () => { }
    //         )
    //     } else {
    //         props.updateInfo(request,
    //             () => { },
    //             () => { })
    //     }
    // }

    return (
        <PageContainer
            navBarTitle={"Products"}
        >
            <PageContentContainer>
                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Customer Info</Text>
                        {editCustomerInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditCustomerInfo(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditCustomerInfo(true)} />}
                    </div>
                    <SectionContent>
                        <InputField
                            label="Customer ID"
                            disabled={true}
                            value={"Cust-" + customer.id.toString()}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="First Name"
                            disabled={!editCustomerInfo}
                            value={customer.firstName.toString()}
                            onChange={(val: string) => setCustomer({ ...customer, firstName: val })}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="Last Name"
                            value={customer.lastName.toString()}
                            onChange={(val: string) => setCustomer({ ...customer, lastName: val })}
                            disabled={!editCustomerInfo}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <PasswordResetField
                            label="Password"
                            disabled={!editPassword}
                            value={!editPassword ? "**************" : password}
                            onClickReset={() => {
                                setEditPassword(!editPassword)
                                if (!editCustomerInfo) setEditCustomerInfo(true)
                            }}
                            onChange={setPassword}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="Email"
                            value={customer.email.toString()}
                            onChange={(val: string) => setCustomer({ ...customer, email: val })}
                            disabled={!editCustomerInfo}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="Phone Number"
                            value={customer.phoneNumber.toString()}
                            onChange={(val: string) => setCustomer({ ...customer, phoneNumber: val })}
                            disabled={!editCustomerInfo}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <CheckableText
                            label="Active Customer"
                            disabled={!editCustomerInfo}
                            value={customer.active}
                            toggle={(val: boolean) => setCustomer({ ...customer, active: val })}
                            containerStyles={{ marginBottom: 15 }}
                        />

                        <CheckableText
                            label="Used Social Auth"
                            disabled={true}
                            value={customer.usedSocialAuth}
                            containerStyles={{ marginBottom: 15 }}
                        />

                        <InputField
                            label="Social Auth Provider"
                            value={customer.socialAuthProvider}
                            // onChange={(val: string) => setCustomer({ ...customer, phoneNumber: val })}
                            disabled={true}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />

                        {editCustomerInfo && <ButtonContainer>
                            <Button
                                label="Save"
                                containerStyles={{ width: '100%', marginBottom: width && width >= 600 ? 0 : 15 }}
                                onClick={() => {
                                    // createUpdateCustomerInfo()
                                    setEditCustomerInfo(false)
                                    setEditPassword(false)
                                }}
                            />
                            <Button
                                label="Cancel"
                                containerStyles={{ width: '100%', border: `2px solid ${COLORS.red}`, backgroundColor: "#fff", color: COLORS.red }}
                                onClick={() => setEditCustomerInfo(false)}
                            />
                        </ButtonContainer>}
                    </SectionContent>
                </SectionContainer>

                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Customer Addresses</Text>
                        {editCustomerInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditCustomerInfo(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditCustomerInfo(true)} />}
                    </div>
                    {customer.customerShippingAddressList?.map(item => <>
                        <SectionContent>
                            <InputField
                                label="Address Line 1"
                                disabled={!editCustomerInfo}
                                value={item.addressLine1}
                                containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                            />
                            <InputField
                                label="Address Line 2"
                                disabled={!editCustomerInfo}
                                value={item.addressLine2}
                                // onChange={(val: string) => setCustomer({ ...customer, firstName: val })}
                                containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                            />
                            <InputField
                                label="City"
                                value={item.city}
                                // onChange={(val: string) => setCustomer({ ...customer, lastName: val })}
                                disabled={!editCustomerInfo}
                                containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                            />
                            <InputField
                                label="Parish"
                                value={item.parishStateProvince}
                                // onChange={(val: string) => setCustomer({ ...customer, email: val })}
                                disabled={!editCustomerInfo}
                                containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                            />
                            <InputField
                                label="Address Type"
                                value={item.isKnutsfordAddress ? "Knutsford" : item.isZipmailAddress ? "Zipmail" : "Normal/Home/P.O"}
                                // onChange={(val: string) => setCustomer({ ...customer, phoneNumber: val })}
                                disabled={true}
                                containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                            />
                            {editCustomerInfo && <ButtonContainer>
                                <Button
                                    label="Save"
                                    containerStyles={{ width: '100%', marginBottom: width && width >= 600 ? 0 : 15 }}
                                    onClick={() => {
                                        // createUpdateCustomerInfo()
                                        setEditCustomerInfo(false)
                                        setEditPassword(false)
                                    }}
                                />

                                <Button
                                    label="Cancel"
                                    containerStyles={{ width: '100%', border: `2px solid ${COLORS.red}`, backgroundColor: "#fff", color: COLORS.red }}
                                    onClick={() => setEditCustomerInfo(false)}
                                />

                                <Button
                                    label="Delete"
                                    containerStyles={{ width: '100%', marginBottom: width && width >= 600 ? 0 : 15, backgroundColor: `${COLORS.red}` }}
                                    // onClick={() => {
                                    //     // createUpdateCustomerInfo()
                                    //     setEditCustomerInfo(false)
                                    //     setEditPassword(false)
                                    // }}
                                />
                            </ButtonContainer>}



                        </SectionContent>
                        <hr style={{ width: '100%', height: 1 }} />
                    </>
                    )}


                </SectionContainer>
            </PageContentContainer>
        </PageContainer>
    )
}

let PageContentContainer = styled.div`
    padding: 20px;
    width: 100%;
`;

let SectionContainer = styled.div`
     @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        max-width: 720px;
     }
`;

let SectionContent = styled.div`
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: auto;
        grid-auto-flow: row;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 350px);
    }
`;

let ButtonContainer = styled.div`
     @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
     }
`;

let Text = styled.p`
    font-size: 24px;
    color: ${COLORS.black};
    font-weight: 500;
`;


function mapStateToProps(state: any) {
    return {
        customers: state.customers,
        // customerProperties: state.customerProperties
    }
}

function mapDispatchToProps(dispatch: Function) {
    return {
        // updateInfo: (request: CustomerRequest, onSuccess: Function, onError: Function) => {
        //     dispatch(updateCustomerInfo(request, onSuccess, onError));
        // },
        // createInfo: (request: CustomerRequest, onSuccess: Function, onError: Function) => {
        //     dispatch(createCustomerInfo(request, onSuccess, onError));
        // }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerInfo);