import {createSlice} from '@reduxjs/toolkit'
import {Order, OrderDetail} from '../../types/order/orderInterface';

const initialState = {
    orderList: new Array<Order>(),
    orderDetailList: new Array<{orderId: number, orderDetails: Array<OrderDetail>}>(),
    error: null,
    pageNo: 0
}

const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        addOrderList(state, action) {
            // if (state.orderList === undefined)
                return { ...state, orderList: [...action.payload.orders], pageNo: action.payload.pageNo, error: null }
            // return { ...state, orderList: [...state.orderList, ...action.payload.orders], pageNo: action.payload.pageNo, error: null }
        },
        addOrderSearchList(state, action) {
            return { ...state, orderList: [...action.payload.orders], pageNo: action.payload.pageNo, error: null }
        },
        updateOrder(state, action){
            return {
                ...state,
                orderList: state.orderList.map((content,index)=> 
                    content.id === action.payload.orderId ? {...content,...action.payload.order} : content),
                error: null
            }
        },
        addOrderDetailList(state, action) {
            if(state.orderDetailList===undefined) 
                return {...state, orderDetailList: [{orderId: action.payload.orderId, orderDetails: action.payload.orderDetails}], error: null}
            return {...state, orderDetailList: [...state.orderDetailList, {orderId: action.payload.orderId, orderDetails: action.payload.orderDetails}], error: null}
        },
        addOrderError(state,action){
            return {...state, error: action.payload.error}
        }
    }
})

export const {addOrderList, addOrderError, addOrderDetailList,updateOrder, addOrderSearchList} = orderSlice.actions;
export default orderSlice.reducer;