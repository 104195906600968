import { addCampaign, addCampaignList, addCampaignError, updateCampaign } from "../../slices/campaignSlices"; 
import APIService from '../../../api/api'
import { CampaignModel } from "../../../types/campaign/campaignInterfaces";

export function createCampaign(data: FormData, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.createCampaign(data)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("201")){
                    dispatch(addCampaign({
                        campaign: response.response
                    }))
                    onSuccess(response.response)
                }else{
                    dispatch(addCampaignError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addCampaignError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function updateCampaignRecord(data: FormData, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.updateCampaign(data)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("OK")){
                    dispatch(updateCampaign(response.response))
                    onSuccess(response.response)
                }else{
                    dispatch(addCampaignError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addCampaignError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getCampaignRecords(queryParam: {pageNo: number, pageSize: number}, onSuccess: Function, onError: Function){
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getCampaignList(queryParam.pageNo, queryParam.pageSize)
        .then(
            (response) => {
                if(response.statusCode.includes("OK")){
                    console.log(response)
                    dispatch(addCampaignList({campaigns: response.response.content}))
                    onSuccess(response.response.content)
                }else{
                    dispatch(addCampaignError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addCampaignError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}