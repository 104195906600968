import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiEdit3, FiXCircle } from "react-icons/fi";
import { useSearchParams } from 'react-router-dom';

import COLORS from '../assets/Colors';
import useWindowDimensions from '../utils/useWindowDimensions';
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import PageContainer from '../components/universal/PageContainer'
import InputField from '../components/Form/InputField';
import DropdownField from '../components/Form/dropdownField';
import CheckableText from '../components/Form/CheckableText';
import PointList from '../components/Form/pointList';
import Button from '../components/Form/Button';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import { Staff, StaffRequest, StaffRole } from '../types/staff/staffInterface';
import { connect } from 'react-redux';
import { updateStaffInfo, createStaffInfo } from '../redux/thunks/staff/staffThunk';
import PasswordResetField from '../components/Form/ResetPasswordField';

let StaffInfo: FC<any> = (props) => {
    let { width, height } = useWindowDimensions()

    let [editStaffInfo, setEditStaffInfo] = useState(false);
    let authCheck = useAuthenticationCheck()
    const [searchParams, setSearchParams] = useSearchParams();

    let [staff, setStaff] = useState<Staff>({
        staffId: 0,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
        permission: Array<string>(),
        staffRole: {
            roleId: 0,
            roleName: "",
            roleDescription: ""
        },
        active: false
    })
    let [password, setPassword] = useState<string | null>("");
    let [editPassword, setEditPassword] = useState<boolean>(false);

    useEffect(() => {
        authCheck()
    }, [])

    useEffect(() => {
        if (searchParams.get("staffId") !== null) {
            let queryOrderId = searchParams.get("staffId") || ""
            if (queryOrderId !== "") findStaffInState(parseInt(queryOrderId))
        }
    }, [])

    useEffect(() => {
        if (searchParams.get("create_new_staff") !== null && searchParams.get("create_new_staff") === "true") {
            setEditPassword(true);
            setEditStaffInfo(true)
        }
    }, [searchParams])

    let findStaffInState = (staffId: number) => {
        let result: Staff = props.staff.staffList.find((item: Staff) => item.staffId == staffId);
        console.log(result);
        if (result !== null) setStaff(result)
    }
    let createUpdateStaffInfo = () => {
        setEditPassword(false);
        setEditStaffInfo(false)
        let request: StaffRequest = {
            ...staff,
            password: null
        }

        if (password !== "") request.password = password;
        setPassword("")

        if (staff.staffId === 0) {
            props.createInfo(request,
                (createdStaff: Staff) => {
                    setStaff(createdStaff)
                    setSearchParams({ "create_new_staff": "false" })
                },
                () => { }
            )
        } else {
            props.updateInfo(request,
                () => { },
                () => { })
        }
    }

    return (
        <PageContainer
            navBarTitle={"Products"}
        >
            <PageContentContainer>
                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Staff Info</Text>
                        {editStaffInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditStaffInfo(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditStaffInfo(true)} />}
                    </div>
                    <SectionContent>
                        <InputField
                            label="Staff ID"
                            disabled={true}
                            value={"st-" + staff.staffId.toString()}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="First Name"
                            disabled={!editStaffInfo}
                            value={staff.firstName.toString()}
                            onChange={(val: string) => setStaff({ ...staff, firstName: val })}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="Last Name"
                            value={staff.lastName.toString()}
                            onChange={(val: string) => setStaff({ ...staff, lastName: val })}
                            disabled={!editStaffInfo}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <PasswordResetField
                            label="Password"
                            disabled={!editPassword}
                            value={!editPassword ? "**************" : password}
                            onClickReset={() => {
                                setEditPassword(!editPassword)
                                if (!editStaffInfo) setEditStaffInfo(true)
                            }}
                            onChange={setPassword}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="Email"
                            value={staff.email.toString()}
                            onChange={(val: string) => setStaff({ ...staff, email: val })}
                            disabled={!editStaffInfo}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="Phone Number"
                            value={staff.phoneNumber.toString()}
                            onChange={(val: string) => setStaff({ ...staff, phoneNumber: val })}
                            disabled={!editStaffInfo}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <DropdownField
                            label="Role"
                            value={staff.staffRole ? staff.staffRole.roleName.toString() : ''}
                            dropdownOptions={props.staffProperties.rolesList.map((item: StaffRole) => item.roleName)}
                            disabled={!editStaffInfo}
                            onChange={(val: string) => setStaff({ ...staff, staffRole: props.staffProperties.rolesList.find((item: StaffRole) => item.roleName === val) })}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <CheckableText
                            label="Active Staff"
                            disabled={!editStaffInfo}
                            value={staff.active}
                            toggle={(val: boolean) => setStaff({ ...staff, active: val })}
                            containerStyles={{ marginBottom: 15 }}
                        />
                        <PointList
                            edit={editStaffInfo}
                            label="Staff Permissions"
                            list={staff.permission}
                            useDropdown={true}
                            updateList={(val: string) => {
                                setStaff({ ...staff, permission: [...staff.permission, val] })
                            }}
                            removeListItem={(newArray: Array<string>) => { setStaff({ ...staff, permission: newArray }) }}
                            dropdownList={props.staffProperties.permissionList.filter((x: any) => !staff.permission.includes(x.permissionCode)).map((obj: any) => obj.permissionCode)}
                        />
                        {editStaffInfo && <ButtonContainer>
                            <Button
                                label="Save"
                                containerStyles={{ width: '100%', marginBottom: width && width >= 600 ? 0 : 15 }}
                                onClick={() => {
                                    createUpdateStaffInfo()
                                    setEditStaffInfo(false)
                                    setEditPassword(false)
                                }}
                            />
                            <Button
                                label="Cancel"
                                containerStyles={{ width: '100%', border: `2px solid ${COLORS.red}`, backgroundColor: "#fff", color: COLORS.red }}
                                onClick={() => setEditStaffInfo(false)}
                            />
                        </ButtonContainer>}
                    </SectionContent>
                </SectionContainer>
            </PageContentContainer>
        </PageContainer>
    )
}

let PageContentContainer = styled.div`
    padding: 20px;
    width: 100%;
`;

let SectionContainer = styled.div`
     @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        max-width: 720px;
     }
`;

let SectionContent = styled.div`
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: auto;
        grid-auto-flow: row;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 350px);
    }
`;

let ButtonContainer = styled.div`
     @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
     }
`;

let Text = styled.p`
    font-size: 24px;
    color: ${COLORS.black};
    font-weight: 500;
`;


function mapStateToProps(state: any) {
    return {
        staff: state.staff,
        staffProperties: state.staffProperties
    }
}

function mapDispatchToProps(dispatch: Function) {
    return {
        updateInfo: (request: StaffRequest, onSuccess: Function, onError: Function) => {
            dispatch(updateStaffInfo(request, onSuccess, onError));
        },
        createInfo: (request: StaffRequest, onSuccess: Function, onError: Function) => {
            dispatch(createStaffInfo(request, onSuccess, onError));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffInfo);