import { createSlice } from '@reduxjs/toolkit'
import { StaffRole } from '../../types/staff/staffInterface'

const initialState = {
    permissionList: new Array<string>(), 
    rolesList: new Array<StaffRole>(),
    error: null
}

const staffPropertiesSlice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        setPermissionsList(state, action){
            return {...state, permissionList: [...state.permissionList, ...action.payload.permissions], error: null}
        },
        setStaffRolesList(state, action){
            return {...state, rolesList: [...state.rolesList,...action.payload.roles], error: null}
        },
        setPropertiesError(state, action){
            return {...state, error: action.payload.error}
        },

    }
})

export const {setPermissionsList,setPropertiesError,setStaffRolesList} = staffPropertiesSlice.actions;

export default staffPropertiesSlice.reducer;