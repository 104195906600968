import React, { CSSProperties, FC, ReactElement} from 'react';
import styled from 'styled-components';

import COLORS from '../../assets/Colors';

interface InputFieldInterface {
    containerStyles?: CSSProperties;
    textFieldStyles?: CSSProperties;
    inputStyles?: CSSProperties;
    placeholder?: string;
    icon?: ReactElement;
    label?: string;
    disabled?: boolean;
    type?: string;
    value?: string | any;
    onChange?: Function;
    min?: number;
    max?: number;
    submit?: Function
}

let TextArea: FC<InputFieldInterface> = (props) => {
    return (
        <Container
            style={props.containerStyles}
        >
            {props.label&&<Text>{props.label}</Text>}
            <FieldContainer
                style={{...props.inputStyles, paddingLeft: props.icon? 10 : 20, backgroundColor: props.disabled ? COLORS.border : "#fff"}}
            >
                {props.icon? props.icon : null}
                <TextAreaItem
                    style={{...props.textFieldStyles, backgroundColor: props.disabled ? COLORS.border : "#fff"}}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    onKeyPress={(event)=>{
                        if(event.key==='Enter'){
                            console.log("here")
                            if(props.submit) props.submit()
                        }
                    }}
                    value={props.value}
                    onChange={(event)=> {if(props.onChange)props.onChange(event.target.value)}}
                />
            </FieldContainer>

        </Container>
    )
}

let Container = styled.div`
    width: 100%;
`;

let FieldContainer = styled.div`
    /* width: 100%; */
    height: 150px;
    border: 2px solid ${COLORS.border};
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

let TextAreaItem = styled.textarea`
    width: 100%;
    height: 100%;
    font-size: 16px;    
    color: ${COLORS.grey1};
    border-style: none;
    padding-left: 10px;
    &:focus {
        outline: 0;
    }
`;

let Text = styled.p`
    font-size: 16px;
    color: ${COLORS.black};
    font-weight: 400;
    margin-bottom: 5px;
`;

export default TextArea;