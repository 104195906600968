import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { FiBox, FiChevronRight } from 'react-icons/fi';

import COLORS from '../../assets/Colors';

interface ProductCardInterface {
    productName: string;
    orderPrice: number;
    orderQuantity: number;
    category: string;
    productId: number;
    containerStyles?: CSSProperties;
    onClick?: Function;
    productImage?: string | null;
}

let OrderProductCard: FC<ProductCardInterface> = (props) => {
    return (
        <Container style={props.containerStyles} onClick={()=>{if(props.onClick) props.onClick()}}>
            <Row style={{paddingBottom: 10}}>
                <Row>
                    <FiBox size={25} color={COLORS.black} style={{marginRight: 10}}/>
                    <Text>Prod-{props.productId}</Text>
                </Row>
                <FiChevronRight size={25} color={COLORS.black} style={{marginRight: 10}}/>
            </Row>
            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center',paddingBottom: 10}}>
                <img
                    src={props.productImage ? props.productImage : '/images/undraw_polaroid_re_481f.svg'}
                    style={{
                        height: 150
                    }}
                />
            </Row>
            <Row style={{padding: 5, paddingLeft: 10, paddingRight: 10, backgroundColor: COLORS.background, borderRadius: 8}}>
                <Text style={{fontWeight: 400, color: COLORS.grey1}}>Product Name</Text>
                <Text style={{textAlign: 'right'}}>{props.productName}</Text>
            </Row>
            <Row style={{padding: 5, paddingLeft: 10, paddingRight: 10}}>
                <Text style={{fontWeight: 400, color: COLORS.grey1}}>Price</Text>
                <Text style={{textAlign: 'right'}}> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.orderPrice)}</Text>
            </Row>
            <Row style={{padding: 5, paddingLeft: 10, paddingRight: 10, backgroundColor: COLORS.background, borderRadius: 8}}>
                <Text style={{fontWeight: 400, color: COLORS.grey1}}>Quantity</Text>
                <Text style={{textAlign: 'right'}}>{props.orderQuantity}</Text>
            </Row>
            <Row style={{padding: 5, paddingLeft: 10, paddingRight: 10}}>
                <Text style={{fontWeight: 400, color: COLORS.grey1}}>Category</Text>
                <Text style={{textAlign: 'right'}}>{props.category}</Text>
            </Row>
            
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <Text style={{ fontWeight: 400, color: COLORS.red, textDecoration: 'underline', marginRight: 20, cursor: 'pointer' }}>Delete</Text>
                <Text style={{ color: COLORS.blue, textDecoration: 'underline', cursor: 'pointer' }}>Edit</Text>
            </div>
        </Container>
    )
}

let Container = styled.div`
    padding: 15px;
    background-color: #fff;
    border: 2px solid ${COLORS.border};
    border-radius: 8px;
    min-width: 300px;
    max-width: 380px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    &:hover{
        cursor: pointer;
    }
`;

let Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

let Text = styled.p`
    font-size: 16px;
    color: ${COLORS.black};
    font-weight: 500;
`;

export default OrderProductCard;