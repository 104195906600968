import React, { FC, useState } from 'react';
import styled from "styled-components";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import COLORS from '../../assets/Colors';
import Button from '../Form/Button';
import Divider from '@mui/material/Divider/Divider';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { format } from 'date-fns';

interface ConfirmationModalInterface {
    message: string;
    closeModal: Function;
    onClickConfirm: Function;
}

let DateRangeReportModal: FC<ConfirmationModalInterface> = (props) => {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text style={{ marginBottom: "15px", textAlign: "center", fontWeight: '500', fontSize: "18px" }}>Attention Required</Text>
                <Text style={{ marginBottom: "15px", textAlign: "center" }}>{props.message}</Text>

                <div style={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>

                    <Accordion
                        style={{
                            maxWidth: 390,
                            minWidth: 335,
                            boxShadow: 'none',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Text style={{ marginLeft: 15, fontWeight: 500, fontSize: 18 }}>Start Date</Text>
                                <Text
                                    style={{ fontWeight: 500 }}
                                >{format(startDate, 'MMM dd yyyy')}</Text>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DayPicker
                                mode="single"
                                selected={startDate}
                                onSelect={(date) => { if (date) setStartDate(date) }}
                            // footer={footer}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        style={{
                            maxWidth: 390,
                            minWidth: 335,
                            boxShadow: 'none',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Text style={{ marginLeft: 15, fontWeight: 500, fontSize: 18 }}>End Date</Text>
                                <Text
                                    style={{ fontWeight: 500 }}
                                >{format(endDate, 'MMM dd yyyy')}</Text>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DayPicker
                                mode="single"
                                selected={endDate}
                                onSelect={(date) => { if (date) setEndDate(date) }}
                            // footer={footer}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <Button
                        label="Cancel"
                        containerStyles={{ backgroundColor: "#fff", color: COLORS.red, border: `2px solid ${COLORS.red}`, marginRight: 10 }}
                        onClick={() => props.closeModal()}
                    />
                    <Button
                        label="Confirm"
                        containerStyles={{ marginLeft: 10 }}
                        onClick={() => props.onClickConfirm({ startDate, endDate })}
                    />
                </div>
            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    /* position: fixed; */
    align-items: center;
    justify-content: center;
`;

let ModalContainer = styled.div`
    min-width: 300px;
    max-width: 380px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
`;

let Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${COLORS.black};
`;

export default DateRangeReportModal;