import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';

import COLORS from '../../assets/Colors';

interface LabeledTextInterface {
    label: string;
    value: any;
    containerStyles?: CSSProperties;
}

let LabeledText:FC<LabeledTextInterface> = (props) => {
    return (
        <Container style={props.containerStyles}>
            <Text>{props.label}</Text>
            <Text style={{fontSize: 18, color: COLORS.grey1}}>{props.value}</Text>
        </Container>
    )
}

let Container = styled.div`

`;

let Text = styled.p`
    font-size: 16px;
    color: ${COLORS.black};
    font-weight: 400;
`;

export default LabeledText;