import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiUser, FiShoppingBag, FiBox, FiMapPin, FiUsers, FiShield, FiDollarSign, FiBookmark } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import COLORS from '../../assets/Colors';

let NavbarDesktop = () => {
    let [activeTab, setActive] = useState("order")
    let navigate = useNavigate();

    useEffect(() =>{
        if(window.location.pathname.includes("product/list")) setActive("product")
        if(window.location.pathname.includes("order/list")) setActive("order")
        if(window.location.pathname.includes("pos/list")) setActive("pos")
        if(window.location.pathname.includes("customer/list")) setActive("customer")
        if(window.location.pathname.includes("staff/list")) setActive("staff")
        if(window.location.pathname.includes("campaign/list")) setActive("campaign")
    },[]);

    return (
        <Container>
            <div
                style={{
                    paddingBottom: '20px',
                    borderBottom: '2px solid #fff'
                }}
            >
                <Logo src={"/images/logo/logo-white-svg.svg"} />
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifySelf: 'center',
                    paddingTop: '20px'
                }}
            >
                <FiShoppingBag size={25} style={{ marginBottom: '20px' }} color={activeTab==="order" ? COLORS.orange : "#fff"} onClick={()=>navigate("/order/list")}/>
                <FiDollarSign size={25} style={{ marginBottom: '20px' }} color={activeTab==="pos" ? COLORS.orange : "#fff"} onClick={()=>navigate("/pos/list")}/>
                <FiBox size={25} style={{ marginBottom: '20px' }} color={activeTab==="product" ? COLORS.orange : "#fff"} onClick={()=>navigate("/product/list")}/>
                <FiBookmark size={25} style={{ marginBottom: '20px' }} color={activeTab==="campaign" ? COLORS.orange : "#fff"} onClick={()=>navigate("/campaign/list")}/>
                <FiShield size={25} style={{ marginBottom: '20px' }} color={activeTab==="staff" ? COLORS.orange : "#fff"} onClick={()=>navigate("/staff/list")}/>
                <FiUsers size={25} style={{ marginBottom: '20px' }} color={activeTab==="customer" ? COLORS.orange : "#fff"} onClick={()=>navigate("/customer/list")}/>

            </div>

            <div style={{
                background: '#fff',
                borderRadius: '100%',
                height: '30px',
                width: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'flex-end',
                justifySelf: 'center',
            }}>
                <FiUser size={20} color={COLORS.black} />
            </div>

            {/* <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 'inherit',
            }}>

            </div> */}
        </Container>
    )
}

let Container = styled.div`
    background-color: ${COLORS.black};
    padding: 20px;
    padding-left: 15px;
    padding-right: 15px;
    display: grid;
    grid-template-rows: 1fr 8fr 1fr;
`;

let Logo = styled.img`
    height: 40px;
`;

export default NavbarDesktop;