import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FiUser, FiLock } from "react-icons/fi";

//import thunk actions
import { userLogin } from '../redux/thunks/auth/authThunks'

import COLORS from '../assets/Colors';
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';

//component import
import InputField from '../components/Form/InputField'
import Button from '../components/Form/Button'
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../components/modal/ModalContainer';
import MessageModal from '../components/modal/MessageModal';

let LoginPage: FC<any> = (props) => {

    let [email, setEmail] = useState("")
    let [password, setPassword] = useState("")
    let [modalMessage, setModalMessage] = useState("")
    let [messageModalVisible, setModalMessageVisible] = useState(false)

    let navigate = useNavigate();

    let handleSubmit = () => {
        let data = { email, password }
        props.login(
            data,
            () => {
                navigate("/order/list")
            },
            (message: string) => {
                setModalMessage(message)
                setModalMessageVisible(true)
            }
        )
    }

    return (
        <PageContainer>
            <LoginCardContainer>

                <Logo
                    src="/images/logo/logo-orange-svg.svg"
                />

                <InputField
                    icon={<FiUser size={30} color={COLORS.grey1} />}
                    placeholder="Staff ID"
                    containerStyles={{ marginBottom: '20px' }}
                    onChange={setEmail}
                    type="email"
                />
                <InputField
                    icon={<FiLock size={30} color={COLORS.grey1} />}
                    placeholder="Password"
                    containerStyles={{ marginBottom: '20px' }}
                    onChange={setPassword}
                    type="password"
                />
                <Button
                    label="Login"
                    onClick={handleSubmit}
                />
            </LoginCardContainer>
            <ModalContainer visible={messageModalVisible}>
                <MessageModal
                    message={modalMessage}
                    onClickConfirm={()=>setModalMessageVisible(false)}
                />
            </ModalContainer>
        </PageContainer>
    )
}

let PageContainer = styled.div`
    background-color: ${COLORS.background};
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;

let LoginCardContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    border-color: ${COLORS.border};
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;


    /* @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        height: 80px;
        max-width: 600px;
    } */
`;

let Logo = styled.img`
    height: 70px;
    margin-bottom: 40px;
    margin-top: 20px;
`;


const mapDispatchToProps = (dispatch: Function) => {
    return {
        login: (data: { email: string, password: string }, onSuccess: Function, onFailure: Function) => {
            dispatch(userLogin(data, onSuccess, onFailure));
        }
    };
};

export default connect(
    null,
    mapDispatchToProps
)(LoginPage);