import React, { CSSProperties, FC, ReactElement } from 'react';
import styled from 'styled-components';
import COLORS from '../../assets/Colors';

interface ButtonInterface {
    label: string;
    containerStyles?: CSSProperties;
    icon?: ReactElement;
    onClick?: Function;
    disabled?: boolean;
}

let Button: FC<ButtonInterface>= (props) => {
    return (
        <ButtonComponent 
            type="button"
            disabled={props.disabled}
            style={{...props.containerStyles}}
            onClick={()=>props.onClick ? props.onClick() : null}
        >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                {props.icon}
                {props.label}
            </div>
        </ButtonComponent>
    )
}

let ButtonComponent = styled.button`
    background-color: ${COLORS.blue};
    height: 35px;
    width: 300px;
    border-radius: 8px;
    border-style: none;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    &:hover{
        cursor: pointer;
    };
    &:disabled{
        background-color: ${COLORS.grey1}
    }
    display: flex; 
    justify-content: center;
    align-items: center;

`;

export default Button;