import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';

let ModalContainer:FC<{visible: boolean, containerStyles?: CSSProperties;}> = (props) => {
    if(!props.visible) return null;
    return (
        <ModalBackgroundContainer>
            {props.children}
        </ModalBackgroundContainer>
    )
}

let ModalBackgroundContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    top: 0;
    padding: 20px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
`;

export default ModalContainer;