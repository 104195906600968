import React, { FC, useEffect, useState } from "react";
import PageContainer from "../components/universal/PageContainer";
import styled from "styled-components";
import COLORS from "../assets/Colors";
import useWindowDimensions from "../utils/useWindowDimensions";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CampaignModal from "../components/modal/CampaignModal";
import { connect } from "react-redux";
import { createCampaign, getCampaignRecords, updateCampaignRecord } from "../redux/thunks/campaign/campaignThunk";
import Button from "../components/Form/Button";
import { CampaignModel } from "../types/campaign/campaignInterfaces";

let CampaignList: FC<any> = (props) => {
    let { width } = useWindowDimensions()

    let [campaignCode, setCampaignCode] = useState("");
    let [campaignId, setCampaignId] = useState(0);
    let [productId, setProductId] = useState(0);
    let [campaignActive, setCampaignActive] = useState(true);
    const [pictures, setPictures] = useState<any>([]);

    let [campaignModalVisible, setCampaignModalVisible] = useState(false);
    let [newCampaign, setNewCampaign] = useState(true);

    useEffect(() => {
        callGetCampaignList()
    }, [])

    let callGetCampaignList = () => {
        props.getCampaignList({}, () => { }, () => { })
    }

    const onDrop = (picture: any) => {
        setPictures([...picture]);
    };

    let createNewCampaign = () => {
        let formdata = new FormData();
        formdata.append("campaignCode", campaignCode);
        formdata.append("productId", productId.toString());
        formdata.append("active", `${campaignActive}`);
        formdata.append("image", pictures[0]);

        props.createCampaign(formdata, ()=> {}, ()=> {})
        setCampaignModalVisible(false);
        setNewCampaign(false)
        setPictures([])
    }

    let updateCampaign = () => {
        let formdata = new FormData();
        formdata.append("campaignId", campaignId.toString());
        formdata.append("campaignCode", campaignCode);
        formdata.append("productId", productId.toString());
        formdata.append("active", `${campaignActive}`);
        if(pictures[0]) formdata.append("image", pictures[0]);

        props.updateCampaign(formdata, ()=> {}, ()=> {})
        setCampaignModalVisible(false);
        setNewCampaign(false)
        setPictures([])
    }

    const columns: GridColDef[] = [
        { field: 'campaignId', headerName: 'Campaign ID', width: 130 },
        { field: 'campaignCode', headerName: 'Campaign Code', width: 130 },
        {
            field: 'campaignPictureUrl', headerName: 'Picture', width: 130,
            renderCell: (params: GridRenderCellParams<string>) => (
                <a href={params.value} id="Product Image" target="_blank" rel="noopener noreferrer">Image</a>
            )
        },
        {
            field: 'productId', headerName: 'Product', width: 130,
            renderCell: (params: GridRenderCellParams<string>) => (
                <a href={"/product/info?productId=63"} id="Product Link" target="_blank" rel="noopener noreferrer">Product page</a>
            )
        },
        { field: 'active', headerName: 'is Active', width: 130 },

    ]

    return (
        <PageContainer
            navBarTitle="Campaigns"
        >

            <PageContentContainer>
                {width && width > 1024 && <Text>Campaigns</Text>}

                <Button
                    // icon={<FiFilter size={25} color={COLORS.border} style={{ marginRight: 10 }} />}
                    label="Create Campaign"
                    onClick={() => {
                        setCampaignModalVisible(true);
                        setNewCampaign(true)
                    }}
                    containerStyles={{
                        width: 160,
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: COLORS.blue,
                        color: "#fff"
                    }}
                />

                <div style={{
                    height: '100%',
                    width: '100%'
                }}>
                    <DataGrid
                        rows={props.campaign.campaignList}
                        columns={columns}
                        getRowId={(row) => row.campaignId}
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids)
                            const selectedRowData: [CampaignModel] = props.campaign.campaignList.filter((item: CampaignModel) =>
                                selectedIds.has(item.campaignId)
                            );
                            setCampaignId(selectedRowData[0].campaignId)
                            setCampaignCode(selectedRowData[0].campaignCode)
                            setCampaignActive(selectedRowData[0].active)
                            setProductId(selectedRowData[0].productId)
                            setNewCampaign(false)
                            setCampaignModalVisible(true)
                            // setCampaignId(selectedRowData[0].campaignId)
                        }}
                        pagination
                    />

                </div>
            </PageContentContainer>

            {campaignModalVisible && <CampaignModal
                onClickConfirm={newCampaign ? createNewCampaign : updateCampaign}
                onClickCancel={()=> {
                    setCampaignModalVisible(false);
                    setNewCampaign(false)
                }}
                campaignCode={campaignCode}
                productId={productId}
                active={campaignActive}
                onDrop={onDrop}
                setCampaignCode={setCampaignCode}
                setProductId={setProductId}
                setActive={setCampaignActive}
                update={!newCampaign}
            />}
        </PageContainer>
    )
}

let PageContentContainer = styled.div`
    padding: 20px;
    display: flex;
    gap: 15px;
    flex-direction: column;
    width: 100%;
`;

let Text = styled.p`
    font-size: 32px;
    color: ${COLORS.black};
    font-weight: 500;
`;

function mapStateToProps(state: any) {
    return {
        campaign: state.campaign
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        getCampaignList: (queryParam: { pageNo: number, pageSize: number }, onSuccess: Function, onFailure: Function) => {
            dispatch(getCampaignRecords(queryParam, onSuccess, onFailure));
        },
        updateCampaign: (data: FormData, onSuccess: Function, onFailure: Function) => {
            dispatch(updateCampaignRecord(data, onSuccess, onFailure));
        },
        createCampaign: (data: FormData, onSuccess: Function, onFailure: Function) => {
            dispatch(createCampaign(data, onSuccess, onFailure));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignList);