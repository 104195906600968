import React, { FC } from 'react';
import styled from "styled-components";

import COLORS from '../../assets/Colors';
import { Payment } from '../../types/order/orderInterface';
import Button from '../Form/Button';
import DropdownField from '../Form/dropdownField';
import InputField from '../Form/InputField';

interface PaymentUpdateModalInterface {
    closeModal: Function;
    paymentObject: Payment;
    onChangePaymentObject: Function
    onClickConfirm: Function;
}

let PaymentUpdateModal: FC<PaymentUpdateModalInterface> = (props) => {

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text style={{ marginBottom: "15px", textAlign: "center", fontWeight: '500', fontSize: "18px" }}>Item Added to Cart</Text>
                <Text style={{ marginBottom: "15px" }}>Please select a order status from the list below.</Text>
                <DropdownField
                    dropdownOptions={["CASH","ONLINE"]}
                    value={props.paymentObject.paymentType ? props.paymentObject.paymentType : ""}
                    containerStyles={{ width: '100%', marginBottom: 15 }}
                    onChange={(val: string)=>{
                        props.onChangePaymentObject({...props.paymentObject, paymentType: val})
                    }}
                />
                <InputField
                    label="Amount"
                    disabled={false}
                    type="number"
                    value={props.paymentObject.paymentAmount.toString()}
                    onChange={(val: string)=>{
                        props.onChangePaymentObject({...props.paymentObject, paymentAmount: val})
                    }}
                    inputStyles={{ width: 90 }}
                    containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                />
                <InputField
                    label="Date"
                    disabled={true}
                    type="date"
                    value={props.paymentObject.paymentDate}
                    inputStyles={{ width: 90 }}
                    containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                />
                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <Button
                        label="Cancel"
                        containerStyles={{ backgroundColor: "#fff", color: COLORS.red, border: `2px solid ${COLORS.red}`, marginRight: 10 }}
                        onClick={() => props.closeModal()}
                    />
                    <Button
                        label="Confirm"
                        containerStyles={{ marginLeft: 10 }}
                        // onClick={props.onClickConfirm}
                    />
                </div>
            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    align-items: center;
    justify-content: center;
`;

let ModalContainer = styled.div`
    min-width: 300px;
    max-width: 380px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
`;

let Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${COLORS.black};
`;

export default PaymentUpdateModal;