import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiEdit3, FiXCircle } from "react-icons/fi";
import { connect } from 'react-redux';
import ImageUploader from 'react-images-upload';

import Carousel from 'react-material-ui-carousel'

import COLORS from '../assets/Colors';
import useWindowDimensions from '../utils/useWindowDimensions';
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import PageContainer from '../components/universal/PageContainer'
import InputField from '../components/Form/InputField';
import DropdownField from '../components/Form/dropdownField';
import CheckableText from '../components/Form/CheckableText';
import PointList from '../components/Form/pointList';

import { ProductCategoryConstants, LightingSubCategory, WrapSubCategory, BodyModSubCategory, CareSubCategory, ElectronicsSubCategory } from '../constants/ProductCategories'

//type imports
import { ProductImage, ProductInterface, ProductRequestObjectInterface } from '../types/product/ProductInterface'
import Button from '../components/Form/Button';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import { useSearchParams } from 'react-router-dom';

import { createNewProduct, updateProductInfo, uploadProductImages } from '../redux/thunks/product/productThunk'
import { updatedProductImages } from '../redux/slices/productSlice';
import TextArea from '../components/Form/TextArea';

let ProductInfo: FC<any> = (props) => {

    let { width, height } = useWindowDimensions()

    let [editProductInfo, setEditProductInfo] = useState(false);
    let [editProductDetails, setEditProductDetail] = useState(false);
    let [editPackageContent, setEditPackageContent] = useState(false);
    let [creatingNewProduct, setCreatingNewProduct] = useState(false);

    let [productId, setProductId] = useState("");

    let [tempImageUrls, setTempImageUrls] = useState<Array<string>>([]);
    let [productData, setProductData] = useState<ProductInterface>({
        productId: 0,
        productName: '',
        productDescription: '',
        productPrice: 0,
        quantity: 0,
        status: '',
        details: null,
        category: '',
        subCategory: '',
        isListed: false,
        isFeatured: false,
        weightLb: 0,
        productImage: []
    })

    const [pictures, setPictures] = useState<any>([]);

    let authCheck = useAuthenticationCheck()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        authCheck()
    })

    useEffect(() => {
        if (searchParams.get("productId") !== null) {
            let queryProductId = searchParams.get("productId") || ""
            setProductId(queryProductId)
            if (queryProductId !== "") findProductInState(parseInt(queryProductId))
        }
    }, [])

    useEffect(() => {
        if (searchParams.get("create_new_product") !== null && searchParams.get("create_new_product") === "true") {
            setEditProductInfo(true)
            setEditProductDetail(true)
            setEditPackageContent(true)
            setCreatingNewProduct(true)
        } else {
            setEditProductInfo(false)
            setEditProductDetail(false)
            setEditPackageContent(false)
            setCreatingNewProduct(false)
        }
    }, [searchParams])

    const onDrop = (picture: any) => {
        setPictures([...picture]);
    };


    let findProductInState = (productId: number) => {
        let result: ProductInterface = props.productInfo.products.find((item: ProductInterface) => item.productId == productId);
        if (result) {
            setProductData(result)
        }
    }

    let onSaveSubmit = () => {
        setEditProductInfo(false)
        setEditProductDetail(false)
        setEditPackageContent(false)
        setCreatingNewProduct(false)
        let product: ProductRequestObjectInterface = {
            productName: productData.productName,
            productDescription: productData.productDescription,
            productPrice: productData.productPrice,
            quantity: productData.quantity,
            status: productData.status,
            details: productData.details,
            category: productData.category,
            subCategory: productData.subCategory,
            isListed: productData.isListed,
            isFeatured: productData.isFeatured,
            weightLb: productData.weightLb
        }
        console.log(product)
        if (productId === "") {

            props.createProduct(product,
                (savedProduct: ProductInterface) => {
                    //set product info
                    setProductData(savedProduct)
                    setSearchParams({ "create_new_product": "false" })
                    setProductId(savedProduct.productId.toString())
                    if (pictures.length > 0) {
                        let request = new FormData();
                        request.append("productId", savedProduct.productId.toString())
                        for (const picture of pictures) {
                            request.append("images", picture)
                        }

                        props.uploadProductImages(
                            savedProduct.productId,
                            request,
                            (imageUrls: Array<string>) => {
                                setTempImageUrls(imageUrls)
                            },
                            () => { }
                        )
                    }
                },
                () => {
                    //TODO: display modal
                }
            )
        } else {
            let updatedProduct: ProductInterface = {
                ...product, productId: parseInt(productId),
                productImage: []
            }
            props.updateProductInfo(updatedProduct,
                (updatedInfo: ProductInterface) => {
                    //set product info
                    setProductData(updatedInfo)

                    if (pictures.length > 0) {
                        let request = new FormData();
                        request.append("productId", updatedInfo.productId.toString())
                        for (const picture of pictures) {
                            request.append("images", picture)
                        }

                        props.uploadProductImages(
                            updatedInfo.productId,
                            request,
                            (imageUrls: Array<string>) => {
                                setTempImageUrls(imageUrls)
                            },
                            () => { }
                        )
                    }
                },
                () => {
                    //TODO: display modal
                }
            )
        }
    }

    let getSubCategoryArray = () => {
        if (productData.category === ProductCategoryConstants.lights) {
            return Object.values(LightingSubCategory)
        }

        if (productData.category === ProductCategoryConstants.wraps) {
            return Object.values(WrapSubCategory)
        }

        if (productData.category === ProductCategoryConstants.bodyMod) {
            return Object.values(BodyModSubCategory)
        }

        if (productData.category === ProductCategoryConstants.care) {
            return Object.values(CareSubCategory)
        }

        if (productData.category === ProductCategoryConstants.electronics) {
            return Object.values(ElectronicsSubCategory)
        }

        return []
    }

    let createCarouselImageArray = () => {

        let images = [];

        if (productData.productImage && productData.productImage.length > 0) {
            productData.productImage.forEach((data) => {
                images.push(
                    <div style={{
                        width: '100%',
                        height: 400,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img key={data.imageUrl} src={data.imageUrl} style={{ height: '100%' }} />
                    </div>
                )
            })
        }

        if ((productData.productImage == null) || (productData.productImage.length === 0 && tempImageUrls.length === 0)) {
            images.push(<div style={{
                width: '100%',
                height: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <img key={"placeholder-image"} src={'/images/undraw_polaroid_re_481f.svg'} style={{ height: `100%` }} />
            </div>)
        }

        if (tempImageUrls.length > 0) {
            tempImageUrls.forEach((url) => {
                images.push(<div style={{
                    width: '100%',
                    height: 400,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <img key={url} src={url} style={{ height: '100%' }} />
                </div>)
            })
        }
        return images;
    }

    return (
        <PageContainer
            navBarTitle={"Products"}
        >
            <PageContentContainer>

                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Product Info</Text>
                        {editProductInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditProductInfo(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditProductInfo(true)} />}
                    </div>

                    <div
                        style={{ marginBottom: '20px' }}
                    >
                        <Carousel
                            navButtonsAlwaysVisible={true}
                            animation={'slide'}
                            height={420}
                        >
                            {createCarouselImageArray()}
                        </Carousel>
                    </div>

                    {editProductInfo && <SectionContent>
                        <div>
                            <ImageUploader
                                {...props}
                                withIcon={true}
                                onChange={onDrop}
                                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                                maxFileSize={5242880}
                                withPreview={true}
                            />
                        </div>
                    </SectionContent>}

                    <SectionContent>
                        <InputField
                            label="Product ID"
                            disabled={true}
                            value={productData.productId}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="Product Name"
                            disabled={!editProductInfo}
                            value={productData.productName}
                            onChange={(val: string) => setProductData({ ...productData, productName: val })}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <DropdownField
                            label="Category"
                            dropdownOptions={Object.values(ProductCategoryConstants)}
                            disabled={!creatingNewProduct}
                            value={productData.category}
                            onChange={(val: string) => setProductData({ ...productData, category: val })}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <DropdownField
                            label="Status"
                            dropdownOptions={["Unavailable", "Available"]}
                            disabled={!editProductInfo}
                            value={productData.status}
                            onChange={(val: string) => setProductData({ ...productData, status: val })}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <DropdownField
                            label="Sub Category"
                            dropdownOptions={getSubCategoryArray()}
                            disabled={!creatingNewProduct}
                            value={productData.subCategory}
                            onChange={(val: string) => setProductData({ ...productData, subCategory: val })}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        {/* <InputField
                            label="Product Description"
                            disabled={!editProductInfo}
                            value={productData.productDescription}
                            onChange={(val: string) => setProductData({ ...productData, productDescription: val })}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        /> */}
                        <InputField
                            label="Unit Price"
                            type="number"
                            disabled={!editProductInfo}
                            value={productData.productPrice.toString()}
                            onChange={(val: number) => setProductData({ ...productData, productPrice: val })}
                            textFieldStyles={{ color: COLORS.green }}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                        <InputField
                            label="Available Quantity"
                            disabled={!editProductInfo}
                            type="number"
                            value={productData.quantity.toString()}
                            onChange={(val: number) => setProductData({ ...productData, quantity: val })}
                            inputStyles={{ width: 90 }}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <CheckableText
                                label="Featured"
                                disabled={!editProductInfo}
                                value={productData.isFeatured}
                                toggle={(val: boolean) => setProductData({ ...productData, isFeatured: val })}
                                containerStyles={{ marginBottom: 15 }}
                            />
                            <CheckableText
                                label="Listed"
                                disabled={!editProductInfo}
                                value={productData.isListed}
                                toggle={(val: boolean) => setProductData({ ...productData, isListed: val })}
                                containerStyles={{ marginBottom: 15 }}
                            />
                        </div>

                        <InputField
                            label="Weight(lb)"
                            disabled={!editProductInfo}
                            type="number"
                            value={productData.weightLb.toString()}
                            onChange={(val: number) => setProductData({ ...productData, weightLb: val })}
                            inputStyles={{ width: 90 }}
                            containerStyles={{ width: '100%', maxWidth: '350px', marginBottom: 15 }}
                        />
                    </SectionContent>
                    <div style={{width: '100%'}}>
                        <TextArea
                            label="Product Description"
                            disabled={!editProductInfo}
                            value={productData.productDescription}
                            onChange={(val: string) => setProductData({ ...productData, productDescription: val })}
                            containerStyles={{ marginBottom: 15 }}
                        />
                    </div>
                    {editProductInfo && <ButtonContainer>
                        <Button
                            label="Save"
                            containerStyles={{ width: '100%', marginBottom: width && width >= 600 ? 0 : 15 }}
                            onClick={onSaveSubmit}
                        />
                        <Button
                            label="Cancel"
                            containerStyles={{ width: '100%', border: `2px solid ${COLORS.red}`, backgroundColor: "#fff", color: COLORS.red }}
                            onClick={() => {
                                setEditProductInfo(false)
                                setEditProductDetail(false)
                                setEditPackageContent(false)
                                setCreatingNewProduct(false)
                            }}
                        />
                    </ButtonContainer>}
                </SectionContainer>

                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Product Details</Text>
                        {editProductInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditProductDetail(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditProductDetail(true)} />}
                    </div>
                    <SectionContent style={{ justifyContent: 'flex-start' }}>

                        <PointList
                            edit={editProductDetails}
                            label="Description Points"
                            list={productData.details && productData.details.descriptionPoints ? productData.details.descriptionPoints : []}
                            updateList={(value: string) => {
                                let tempDetails = productData.details;
                                if (productData.details && productData.details.descriptionPoints) {
                                    tempDetails.descriptionPoints = [...tempDetails.descriptionPoints, value]
                                } else if(productData.details===null) {
                                    tempDetails = {descriptionPoints: [value]}
                                }else {
                                    tempDetails.descriptionPoints = [value]
                                }
                                setProductData({ ...productData, details: tempDetails })
                            }}
                        />
                    </SectionContent>
                    {editProductDetails && <ButtonContainer>
                        <Button
                            label="Save"
                            containerStyles={{ width: '100%', marginBottom: width && width >= 600 ? 0 : 15 }}
                            onClick={onSaveSubmit}
                        />
                        <Button
                            label="Cancel"
                            containerStyles={{ width: '100%', border: `2px solid ${COLORS.red}`, backgroundColor: "#fff", color: COLORS.red }}
                            onClick={() => {
                                setEditProductInfo(false)
                                setEditProductDetail(false)
                                setEditPackageContent(false)
                                setCreatingNewProduct(false)
                            }}
                        />
                    </ButtonContainer>}
                </SectionContainer>

                <SectionContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${COLORS.border}`,
                            marginBottom: '20px'
                        }}
                    >
                        <Text>Package Content</Text>
                        {editProductInfo ?
                            <FiXCircle size={20} color={COLORS.red} onClick={() => setEditPackageContent(false)} />
                            : <FiEdit3 size={20} color={COLORS.blue} onClick={() => setEditPackageContent(true)} />}
                    </div>
                    <SectionContent style={{ justifyContent: 'flex-start' }}>
                        <PointList
                            edit={editPackageContent}
                            label="Content"
                            list={productData.details && productData.details.packageContent ? productData.details.packageContent : []}
                            updateList={(value: string) => {
                                let tempDetails = productData.details;
                                if (productData.details && productData.details.packageContent) {
                                    tempDetails.packageContent = [...tempDetails.packageContent, value]
                                }else if(productData.details===null) {
                                    tempDetails = {packageContent: [value]}
                                } else {
                                    tempDetails.packageContent = [value]
                                }
                                setProductData({ ...productData, details: tempDetails })
                            }}
                        />
                    </SectionContent>
                    {editPackageContent && <ButtonContainer>
                        <Button
                            label="Save"
                            containerStyles={{ width: '100%', marginBottom: width && width >= 600 ? 0 : 15 }}
                            onClick={onSaveSubmit}
                        />
                        <Button
                            label="Cancel"
                            containerStyles={{ width: '100%', border: `2px solid ${COLORS.red}`, backgroundColor: "#fff", color: COLORS.red }}
                            onClick={() => {
                                setEditProductInfo(false)
                                setEditProductDetail(false)
                                setEditPackageContent(false)
                                setCreatingNewProduct(false)
                            }}
                        />
                    </ButtonContainer>}
                </SectionContainer>
            </PageContentContainer>
        </PageContainer>
    )
}

let PageContentContainer = styled.div`
    padding: 20px;
    width: 100%;
`;

let SectionContainer = styled.div`
     @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        max-width: 720px;
     }
`;

let SectionContent = styled.div`
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: auto;
        grid-auto-flow: row;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 350px);
    }
`;

let ButtonContainer = styled.div`
     @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
     }
`;


let Text = styled.p`
    font-size: 24px;
    color: ${COLORS.black};
    font-weight: 500;
`;

function mapStateToProps(state: any) {
    return {
        productInfo: state.products
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        createProduct: (data: ProductRequestObjectInterface, onSuccess: Function, onFailure: Function) => {
            dispatch(createNewProduct(data, onSuccess, onFailure));
        },
        updateProductInfo: (data: ProductInterface, onSuccess: Function, onFailure: Function) => {
            dispatch(updateProductInfo(data, onSuccess, onFailure));
        },
        uploadProductImages: (productId: number, request: FormData, onSuccess: Function, onFailure: Function) => {
            dispatch(uploadProductImages(productId, request, onSuccess, onFailure));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductInfo);