import React, { CSSProperties, FC, ReactElement } from 'react';
import styled from 'styled-components';

import COLORS from '../../assets/Colors';
import InputField from './InputField';

interface InputFieldInterface {
    containerStyles?: CSSProperties;
    textFieldStyles?: CSSProperties;
    inputStyles?: CSSProperties;
    placeholder?: string;
    icon?: ReactElement;
    label?: string;
    disabled?: boolean;
    type?: string;
    value?: string | any;
    onChange?: Function;
    onClickReset?: Function;
}
let PasswordResetField:FC<InputFieldInterface> = (props) => {
    return (
        <Container
            style={props.containerStyles}
        >
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                {props.label&&<Text>{props.label}</Text>}
                {props.label&&<Text 
                    onClick={()=>{if(props.onClickReset)props.onClickReset()}}
                    style={{color: COLORS.blue, textDecoration: 'underline', cursor: 'pointer'}}>Reset Password</Text>}
            </div>
            <FieldContainer
                style={{...props.inputStyles, paddingLeft: props.icon? 10 : 20, backgroundColor: props.disabled ? COLORS.border : "#fff"}}
            >
                {props.icon? props.icon : null}
                <Input
                    style={{...props.textFieldStyles, backgroundColor: props.disabled ? COLORS.border : "#fff"}}
                    type={props.type ? props.type : "text"}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(event)=> {if(props.onChange)props.onChange(event.target.value)}}
                />
            </FieldContainer>

        </Container>
    )
}

let Container = styled.div`
    width: 300px;
`;

let FieldContainer = styled.div`
    height: 35px;
    border: 2px solid ${COLORS.border};
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

let Input = styled.input`
    width: 100%;
    font-size: 16px;    
    color: ${COLORS.grey1};
    border-style: none;
    padding-left: 10px;
    &:focus {
        outline: 0;
    }
`;

let Text = styled.p`
    font-size: 16px;
    color: ${COLORS.black};
    font-weight: 400;
    margin-bottom: 5px;
`;


export default PasswordResetField;