import { createSlice } from '@reduxjs/toolkit'
import { Customer } from '../../types/customer/customerInterface'

const initialState = {
    customerList: new Array<Customer>(),
    error: null,
    pageNo: 0
}

const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        addCustomerList(state, action) {
            // if (state.customerList === undefined)
                return { ...state, customerList: [...action.payload.customers], pageNo: action.payload.pageNo, error: null }
            // return { ...state, customerList: [...state.customerList, ...action.payload.customers], pageNo: action.payload.pageNo, error: null }
        },
        addCustomerSearchList(state, action) {
            return { ...state, customerList: [...action.payload.customers], pageNo: action.payload.pageNo, error: null }
        },
        addCustomer(state, action) {
            if (state.customerList === undefined)
                return { ...state, customerList: [action.payload.customer], error: null }
            return { ...state, customerList: [...state.customerList, action.payload.customer], error: null }
        },
        addCustomerError(state,action){
            return {...state, error: action.payload.error}
        }
    }
})

export const {addCustomerList, addCustomerError, addCustomer, addCustomerSearchList} = customerSlice.actions;
export default customerSlice.reducer;