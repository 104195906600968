import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';

import COLORS from '../../assets/Colors';

interface CheckableTextInterface {
    label: string;
    containerStyles?: CSSProperties;
    disabled?: boolean;
    toggle?: Function;
    value?: boolean;
}

let CheckableText:FC<CheckableTextInterface> = (props) => {
    return (
        <Container style={props.containerStyles}>
            <Text>{props.label}</Text>
            <input 
                type="checkbox"
                checked={props.value}
                onChange={()=>{
                    if(props.toggle)props.toggle(!props.value)
                }}
                disabled={props.disabled}
            />
        </Container>
    )
}

let Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

let Text = styled.p`
    font-size: 16px;
    color: ${COLORS.black};
    font-weight: 400;
    margin-right: 10px;
`;


export default CheckableText;