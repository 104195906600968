import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiSearch, FiFilter, FiBarChart } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageContainer from '../components/universal/PageContainer'
import TabBar from '../components/universal/TabBar'
import InputField from '../components/Form/InputField';
import COLORS from '../assets/Colors';
import useWindowDimensions from '../utils/useWindowDimensions';
import { useAuthenticationCheck } from '../utils/authenticationUtils';
import OrderCard from '../components/cards/OrderCard'
import { SCREEN_WIDTH_CONSTANTS } from '../assets/Screens';
import { getOrderFilterResult, getOrderList, getOrderSearchResult } from '../redux/thunks/order/orderThunk'
import { Order, OrderFilterRequest, orderSearchRequestModel } from './../types/order/orderInterface';
import DropdownField from '../components/Form/dropdownField';
import PageStepper from '../components/universal/PageSteper';
import Button from '../components/Form/Button';
import ModalContainer from '../components/modal/ModalContainer';
import DateRangeReportModal from '../components/modal/DateRangeReportModal';
import APIService from '../api/api';
import { format } from 'date-fns';
import LoadingModal from '../components/modal/LoadingModal';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

let OrderList: FC<any> = (props) => {

    let { width, height } = useWindowDimensions()
    let authCheck = useAuthenticationCheck()
    let navigate = useNavigate();

    let [searchString, setSearchString] = useState("");

    let [pageNumber, setPageNumber] = useState(0);
    let [totalElements, setTotalElements] = useState(0);
    let [totalPages, setTotalPages] = useState(1);
    let [lastPage, setIsLastPage] = useState(true);
    let [pageSize, setPageSize] = useState(50);

    let [showReportDateSelectionModal, setShowReportDateSelectionModal] = useState(false);
    let [showLoadingModal, setShowLoadingModal] = useState(false);
    let [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
    let [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
    let [successSnackBarMessage, setSuccessSnackBarMessage] = useState("");
    let [errorSnackBarMessage, setErrorSnackBarMessage] = useState("");

    let updatePageNumber = (updatedPageNumber: number) => {
        setPageNumber(updatedPageNumber);
        handleGetOrderList(pageNumber, pageSize);
    }


    useEffect(() => {
        authCheck()
        if (props.orders.orderList === undefined || props.orders.orderList.length == 0) {
            handleGetOrderList()
        }
    }, [])

    let handleGetOrderList = (pageNo: number = 0, pageSize: number = 100) => {
        props.getOrderList(
            { pageNo, pageSize },
            (response: any) => {
                setPageSize(response.pageSize)
                setTotalElements(response.totalElements)
                setTotalPages(response.totalPages)
                setIsLastPage(response.last)
            },
            () => {
                //TODO: show modal
            },
        )
    }

    let handleGetOrderReportWithinDateRange = (startDate: Date, endDate: Date) => {
        let apiService = new APIService();
        // debugger
        let start = format(startDate, 'yyyy-MM-dd')
        let end = format(endDate, 'yyyy-MM-dd')

        setShowLoadingModal(true)
        setShowReportDateSelectionModal(false)
        apiService.getOrdersReportWithinDateRange({ startState: start, endDate: end })
            .then(res => {
                setSuccessSnackBarMessage("Report Generated")
                setSuccessSnackBarOpen(true)
                setShowLoadingModal(false)
            })
            .catch(() => {
                setErrorSnackBarMessage("Error Generating Report")
                setErrorSnackBarOpen(true)
                setShowLoadingModal(false)
            })
    }

    let handleClickOrder = (orderId: string | number) => {
        navigate(`/order/info?orderId=${orderId}`)
    }

    let submitSearch = () => {
        let request: orderSearchRequestModel = {
            searchString: searchString,
            pageNumber: 0,
            itemCount: 50
        }
        props.searchOrders(request, () => { }, () => { })
    }

    let filter = (status: string) => {
        let request: OrderFilterRequest = {
            pageNumber: 0,
            itemCount: 50,
            filterStatus: status
        }
        props.filterOrder(request, () => { }, () => { })
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Order ID', width: 130 },
        { field: 'status', headerName: 'Status', width: 130 },
        { field: 'orderMethod', headerName: 'Delivery Method', width: 130 },
        {
            field: 'orderDate',
            headerName: 'Order Date',
            type: 'date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
                `${format(new Date(params.row.orderDate), 'EEE PP')}`,
        },
        {
            field: 'customer', headerName: 'Customer', width: 130,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.customer.firstName + " " + params.row.customer.lastName}`
        },
        {
            field: 'staff', headerName: 'Fullfilled By', width: 130,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.staff !==null ? params.row.staff.firstName + " " + params.row.staff.lastName : "N/A"}`
        },
        {
            field: 'orderTotal',
            headerName: 'Total',
            valueGetter: (params: GridValueGetterParams) =>
                `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.row.orderTotal)}`,
        },
    ];

    return (
        <PageContainer
            navBarTitle={"Orders"}
        >
            <PageContentContainer>
                {width && width > 1024 && <Text>Orders</Text>}
                <SearchContainer>
                    <InputField
                        icon={<FiSearch size={30} color={COLORS.orange} />}
                        placeholder="Search  Order, e.g cust-2 or ord-4"
                        onChange={(value: string) => setSearchString(value)}
                        submit={() => submitSearch()}
                        containerStyles={{ marginBottom: width && width < 600 ? '20px' : 0, width: '100%', maxWidth: '350px', marginRight: width && width < 600 ? 0 : 20 }}
                    />

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        maxWidth: 450
                    }}>
                        <Button
                            // icon={<FiFilter size={25} color={COLORS.border} style={{ marginRight: 10 }} />}
                            label="Generate Report"
                            onClick={() => setShowReportDateSelectionModal(true)}
                            containerStyles={{
                                width: 160,
                                display: 'flex',
                                justifyContent: 'center',
                                border: `2px solid ${COLORS.border}`,
                                backgroundColor: "#fff",
                                color: COLORS.grey1,
                                marginRight: width && width < 600 ? 0 : 20
                            }}
                        />
                    </div>
                </SearchContainer>

                <div
                    style={{
                        marginTop: 10,
                        borderBottom: `2px solid ${COLORS.border}`,
                        paddingBottom: '20px'
                    }}
                >
                    <TabBar
                        tabs={["All", "Unfulfilled", "Fulfilled", "Processing", "Canceled"]}
                        onClick={(status: string) => {
                            if (status !== 'All') {
                                filter(status.toUpperCase())
                            } else {
                                handleGetOrderList()
                            }

                        }}
                    />
                </div>

                <div style={{
                    height: '100%',
                    width: '100%'
                }}>
                    <DataGrid
                        rows={props.orders.orderList}
                        columns={columns}
                        getRowId={(row) => row.id}
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids)
                            const selectedRowData: [Order] = props.orders.orderList.filter((order: Order) =>
                                selectedIds.has(order.id)
                            );
                            handleClickOrder(selectedRowData[0].id)
                        }}
                        pagination
                    />

                </div>

            </PageContentContainer>

            <ModalContainer visible={showReportDateSelectionModal}>
                <DateRangeReportModal
                    message='Select the Start and End date range below'
                    closeModal={() => setShowReportDateSelectionModal(false)}
                    onClickConfirm={(data: { startDate: Date, endDate: Date }) => handleGetOrderReportWithinDateRange(data.startDate, data.endDate)}
                />
            </ModalContainer>
            <ModalContainer visible={showLoadingModal} >
                <LoadingModal />
            </ModalContainer>

            <Snackbar open={successSnackBarOpen} autoHideDuration={6000} onClose={() => setSuccessSnackBarOpen(false)}>
                <Alert onClose={() => setSuccessSnackBarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {successSnackBarMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={errorSnackBarOpen} autoHideDuration={6000} onClose={() => setErrorSnackBarOpen(false)}>
                <Alert onClose={() => setErrorSnackBarOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {errorSnackBarMessage}
                </Alert>
            </Snackbar>
        </PageContainer>
    )
};

let PageContentContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

let SearchContainer = styled.div`
    border-bottom: 2px solid ${COLORS.border};
    padding-bottom: 20px;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;

let OrderListContainer = styled.div`
    margin-top: 20px;
    overflow-y: scroll;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        /* justify-content: center; */
        grid-template-columns: repeat(auto-fit, 350px);
    }
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        grid-template-columns: repeat(auto-fit, 380px);
    }
`;

let Text = styled.p`
    font-size: 32px;
    color: ${COLORS.black};
    font-weight: 500;
    margin-bottom: 15px;
`;

let StepperContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;


function mapStateToProps(state: any) {
    return {
        orders: state.orders
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        getOrderList: (data: { pageNo: number, pageSize: number }, onSuccess: Function, onFailure: Function) => {
            dispatch(getOrderList(data, onSuccess, onFailure));
        },
        searchOrders: (request: orderSearchRequestModel, onSuccess: Function, onError: Function) => {
            dispatch(getOrderSearchResult(request, onSuccess, onError));
        },
        filterOrder: (request: OrderFilterRequest, onSuccess: Function, onError: Function) => {
            dispatch(getOrderFilterResult(request, onSuccess, onError));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderList);