import {addStaffList, addStaffError, updateStaffInformation, addToStaffList, addStaffSearchList} from '../../slices/staffSlices'
import APIService from '../../../api/api'
import { StaffFilterRequest, StaffRequest, StaffSearchRequest } from '../../../types/staff/staffInterface';

export function getStaffList (queryParam: {pageNo: number, pageSize: number}, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getStaffList(queryParam.pageNo, queryParam.pageSize)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addStaffList({
                        staffs: response.response.staff,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess(response.response)
                }else{
                    dispatch(addStaffError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addStaffError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function searchStaffRequest (request: StaffSearchRequest, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.searchStaff(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addStaffSearchList({
                        staffs: response.response.content,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess()
                }else{
                    dispatch(addStaffError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addStaffError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function filterStaffRequest (request: StaffFilterRequest, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.filterStaff(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addStaffSearchList({
                        staffs: response.response.content,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess()
                }else{
                    dispatch(addStaffError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addStaffError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function updateStaffInfo (request: StaffRequest, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.updateStaffInformation(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(updateStaffInformation(response.response.data))
                    onSuccess()
                }else{
                    dispatch(addStaffError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addStaffError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function createStaffInfo (request: StaffRequest, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.createStaffInformation(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("201")){
                    dispatch(addToStaffList(response.response.data))
                    onSuccess(response.response.data)
                }else{
                    dispatch(addStaffError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addStaffError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}