import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//pages import
import LoginPage from "./pages/LoginPage";
import ProductList from "./pages/ProductList";
import OrderList from "./pages/OrderList"
import PosList from "./pages/PosList"
import CustomerList from "./pages/CustomerList"
import StaffList from "./pages/StaffList"
import ProductInfo from "./pages/ProdutInfo"
import OrderInfo from "./pages/OrderInfo"
import StaffInfo from "./pages/StaffInfo"
import CustomerInfo from './pages/CustomerInfo';
import PosInfo from './pages/PosInfo';
import CampaignList from './pages/CampaignList';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage/>}/>
          <Route path="/product/list" element={<ProductList/>}/>
          <Route path="/order/list" element={<OrderList/>}/>
          <Route path="/pos/list" element={<PosList/>}/>
          <Route path="/campaign/list" element={<CampaignList/>}/>
          <Route path="/pos/info" element={<PosInfo/>}/>
          <Route path="/customer/list" element={<CustomerList/>}/>
          <Route path="/staff/list" element={<StaffList/>}/>
          <Route path="/product/info" element={<ProductInfo/>}/>
          <Route path="/order/info" element={<OrderInfo/>}/>
          <Route path="/staff/info" element={<StaffInfo/>}/>
          <Route path="/customer/info" element={<CustomerInfo/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
