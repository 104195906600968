import React, { FC } from 'react';
import styled from "styled-components";

import COLORS from '../../assets/Colors';
import Button from '../Form/Button';

interface ConfirmationModalInterface {
    message: string;
    closeModal: Function;
    onClickConfirm: Function;
}

let ConfirmationModal: FC<ConfirmationModalInterface> = (props) => {

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text style={{marginBottom: "15px", textAlign: "center", fontWeight: '500', fontSize: "18px"}}>Attention Required</Text>
                <Text style={{marginBottom: "15px"}}>{props.message}</Text>

                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <Button
                        label="Cancel"
                        containerStyles={{backgroundColor: "#fff", color: COLORS.red, border: `2px solid ${COLORS.red}`, marginRight: 10}}
                        onClick={() => props.closeModal()}
                    />
                    <Button
                        label="Confirm"
                        containerStyles={{marginLeft: 10}}
                        onClick={props.onClickConfirm}
                    />
                </div>
            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    /* position: fixed; */
    align-items: center;
    justify-content: center;
`;

let ModalContainer = styled.div`
    min-width: 300px;
    max-width: 380px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
`;

let Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${COLORS.black};
`;

export default ConfirmationModal;