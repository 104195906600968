import {addOrderError, addOrderList, addOrderDetailList,updateOrder, addOrderSearchList} from '../../slices/orderSlices'
import APIService from '../../../api/api'
import { Order, OrderFilterRequest, orderSearchRequestModel, OrderUpdateRequest } from '../../../types/order/orderInterface';

export function getOrderList (queryParam: {pageNo: number, pageSize: number}, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getOrderList(queryParam.pageNo, queryParam.pageSize)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addOrderList({
                        orders: response.response.content,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess(response.response)
                }else{
                    dispatch(addOrderError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addOrderError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getOrderSearchResult (request: orderSearchRequestModel, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.searchOrders(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addOrderSearchList({
                        orders: response.response.content,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess()
                }else{
                    dispatch(addOrderError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addOrderError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getOrderFilterResult (request: OrderFilterRequest, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.filterOrders(request)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addOrderSearchList({
                        orders: response.response.content,
                        pageNo: response.response.pageNo
                    }))
                    onSuccess()
                }else{
                    dispatch(addOrderError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addOrderError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}


export function updateOrderInfo (order: OrderUpdateRequest, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.updateOrder(order)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(updateOrder({
                        order: response.response.data,
                        orderId: order.id
                    }))
                    onSuccess(response.response.data)
                }else{
                    dispatch(addOrderError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addOrderError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}

export function getOrderDetailList (orderId: number, onSuccess: Function, onError: Function) {
    return (dispatch: Function, getState: any) => {
        let apiService = new APIService();
        apiService.getOrderDetails(orderId)
        .then(
            (response) => {
                console.log(response)
                if(response.statusCode.includes("200")){
                    dispatch(addOrderDetailList({
                        orderId,
                        orderDetails: response.response.data
                    }))
                    onSuccess(response.response.data)
                }else{
                    dispatch(addOrderError({
                        error: {
                            message: response.message,
                            status: response.statusCode
                        }
                    }))
                    onError()
                }
            }
        )
        .catch(error => {
            console.error(error)
            dispatch(addOrderError({
                error: {
                    message: "Server Error",
                    status: "500"
                }
            }))
        })
    }
}