import { createSlice } from '@reduxjs/toolkit'
import { ProductInterface } from '../../types/product/ProductInterface'

const initialState = {
    products: new Array<ProductInterface>(),
    error: null,
    pageNo: 0
}

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        addProduct(state, action) {
            // if (state.products === undefined)
                return { ...state, products: [action.payload], error: null }
            // return { ...state, products: [...state.products, action.payload], error: null }
        },
        addProductList(state, action) {
            // if (state.products === undefined)
                return { ...state, products: [...action.payload.products], error: null, pageNo: action.payload.pageNo }
            // return { ...state, products: [...state.products, ...action.payload.products], error: null, pageNo: action.payload.pageNo }
        },
        addSearchProductList(state, action) {
            return{ ...state, products: [...action.payload.products], error: null, pageNo: action.payload.pageNo }
        },
        addProductListNoPageNumber(state, action) {
            if (state.products === undefined)
                return { ...state, products: [...action.payload.products], error: null }
            return { ...state, products: [...state.products, ...action.payload.products], error: null }
        },
        updateProduct(state, action) {
            return {
                ...state,
                products: state.products.map((content, index) =>
                    content.productId === action.payload.productId ? { ...content, ...action.payload.product } : content),
                error: null
            }
        },
        addProductError(state, action) {
            if (state.products === undefined)
                return { ...state, products: [], error: action.payload.error, }
            return { ...state, error: action.payload.error, products: [...state.products] }
        },
        updatedProductImages(state, action) {
            return {
                ...state,
                products: state.products.map((content, index) =>
                    content.productId === action.payload.productId ? { ...content, images: content.images ?  [...content.images, action.payload.images] : action.payload.images } : content),
                error: null
            }
        }
    }
})

export const {
    addProduct,
    addProductError,
    addProductList,
    updateProduct,
    addProductListNoPageNumber,
    updatedProductImages,
    addSearchProductList
} = productSlice.actions;

export default productSlice.reducer;